import React, { useEffect } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { useParams } from "react-router-dom";

export default function ClientVideoCall() {
  const { meetingId, name } = useParams();
  const frontendBaseUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : window.location.origin;

  useEffect(() => {
    const apiKey = process.env.REACT_APP_VIDEOSDK_API_KEY;

    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,

      region: "sg001", // region for new meeting

      containerId: null,
      redirectOnLeave: `${frontendBaseUrl}/v2/client/addReview/${meetingId}`,

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: false,
        // webhookUrl: "https://www.videosdk.live/callback",
        // awsDirPath: `/meeting-recordings/${meetingId}/`, // automatically save recording in this s3 path
      },

      // livestream: {
      //   autoStart: true,
      //   enabled: true,
      // },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "PIN", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },
      theme: "LIGHT",
      branding: {
        enabled: true,
        logoURL:
          "https://easytwogether.s3.ap-south-1.amazonaws.com/videosdk_logo.png",
        name: "Peace Twogether",
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: false, // Can toggle meeting recording
        toggleLivestream: true, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },
      waitingScreen: {
        imageUrl:
          "https://easytwogether.s3.ap-south-1.amazonaws.com/videosdk_logo.png",
        text: "Connecting to the meeting...",
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title: "Consultation", // Meeting title
        meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Peace Twogether", // action button label
          href: frontendBaseUrl, // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "sd", // "hd" or "sd"

      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return <div></div>;
}
