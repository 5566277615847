import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
export default function CustomDatePicker({ date, minDate, maxDate, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label="Date"
        inputFormat="DD-MM-yyyy"
        value={date}
        orientation="portrait"
        disablePast
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            id="meeting-date"
            name="meeting-date"
            sx={{ width: "100%" }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}
