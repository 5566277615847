import Typography from "@mui/material/Typography";
import Chat from "../../../utils/chat.png";
import Video from "../../../utils/video.png";
import Professionals from "../../../utils/professionals.png";
import Arrow from "../../../utils/rightArrow.png";
import Hero from "../../../utils/hero.png";
import ChatLady from "../../../utils/chatLady.png";
import Service1 from "../../../utils/service1.png";
import Service2 from "../../../utils/service2.png";
import Service3 from "../../../utils/service3.png";
import Service4 from "../../../utils/service4.png";
import Service5 from "../../../utils/service5.png";
import Service6 from "../../../utils/service6.png";
import legalBuddy from "../../../utils/legalBuddy.png";
import penguin from "../../../utils/hello-pinguin.gif";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  Paper,
  Stack,
} from "@mui/material";
import EasyButton from "../../../components/easyButton";
import live from "../../../utils/live.png";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import { useEffect, useState } from "react";
import LiveConsultantCard from "../../../components/v2/liveConsultantCard";
import LandingHeader from "./landingHeader";
import LandingFooter from "./landingFooter";
import EasyButton2 from "../../../components/v2/easyButton2";

export default function LandingPage() {
  const [consultants, setConsultants] = useState([]);
  const [openEasyBuddy, setOpenEasyBuddy] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      let res = await axios.get(`${BaseURL}/api/landing/liveConsultants`);
      res.data = res.data.filter(
        (obj) =>
          !["Anitha Raman", "Pratyusha Pal", "Akshita Sharma"].includes(
            obj.name
          )
      );
      setConsultants([
        ...res.data,
        {
          _id: "64b798d010d6372505f6b35f",
          name: "Ritesh Bindra",
          photoLink:
            "https://easytwogether.s3.ap-south-1.amazonaws.com/profilePic/64b798d010d6372505f6b35f.png",
          specialisation: "Civil, Criminal, Divorce Law",
        },
        {
          _id: "64e2febcd98e1e2a638bd25a",
          name: "Namrata Chawla",
          photoLink:
            "https://easytwogether.s3.ap-south-1.amazonaws.com/profilePic/64e2febcd98e1e2a638bd25a.png",
          specialisation: "Coaching & Training",
        },
      ]);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchData();
    setTimeout(() => {
      setOpenSupport(true);
    }, 5000);
  }, []);
  return (
    <Stack paddingTop={{ lg: 15, md: 15, sm: 10, xs: 10 }}>
      <LandingHeader />
      <Stack
        flexDirection={{ lg: "row", xs: "column-reverse" }}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        alignItems={"center"}
        justifyContent={{ lg: "space-between" }}
        spacing={{ lg: 10, md: 5, sm: 5, xs: 5 }}
      >
        <Stack alignItems={"start"}>
          <Typography
            fontSize={{ lg: 55, md: 40, sm: 25, xs: 25 }}
            marginBottom={{ lg: 3, md: 2, sm: 1, xs: 1 }}
          >
            Everyone Deserve to be Happy, so do you!
          </Typography>
          <Typography
            fontSize={{ lg: 24, md: 24, sm: 24, xs: 24 }}
            fontWeight={"bold"}
            marginBottom={2}
            display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
          >
            Untangle the tangle, Share your feelings
          </Typography>
          <Typography marginBottom={3}>
            At PeaceTwogether, we understand that seeking help for mental health
            can often be intimidating and overwhelming. That's why we've created
            a safe, non-judgmental, and accessible platform that puts you in
            control of your care.
          </Typography>
          <Stack
            direction={"row"}
            marginTop={2}
            justifyContent={{ md: "start", xs: "center" }}
            width={"100%"}
            spacing={2}
          >
            <Button
              variant="outlined"
              sx={{
                // display: { lg: "block", md: "block", sm: "none", xs: "none" },
                marginLeft: "auto",
                backgroundColor: "#662d91",
                color: "white",
                borderColor: "black",
                px: { sm: 5, xs: 4 },
                "&:hover": {
                  backgroundColor: "#333", // adjust as needed for hover effect
                  borderColor: "black",
                },
              }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </Button>
            <Button
              variant="outlined"
              sx={{
                // display: { lg: "block", md: "block", sm: "none", xs: "none" },
                marginLeft: "auto",
                backgroundColor: "#262262",
                color: "white",
                borderColor: "black",
                px: { sm: 5, xs: 4 },
                "&:hover": {
                  backgroundColor: "#0a9118", // adjust as needed for hover effect
                  borderColor: "black",
                },
              }}
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </Button>
          </Stack>
          <Typography mt={2} color={"GrayText"}>
            For corporate use {"  "}
            <Button
              variant="text"
              sx={{
                color: "#662d91",
                textTransform: "none",
                fontStyle: "italic",
              }}
              onClick={() => navigate("/corporate/landing")}
            >
              Corporate Pro{" "}
            </Button>
          </Typography>
        </Stack>
        <Box
          component="img"
          alt="Happy Life"
          src={Hero}
          sx={{
            width: { xs: "90%", md: "445px" },
            display: "block",
            transition: "0.3s",
            "&:hover": {
              transform: "translateY(-8px)",
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          marginTop: 5,
          backgroundColor: "#FAF2FF",
          alignItems: "center",
          height: "550px",
        }}
      >
        <Typography
          color="#2E2F35"
          py={10}
          px={5}
          fontSize={{ lg: 60, md: 40, sm: 40, xs: 30 }}
          fontWeight={"medium"}
          textAlign={"center"}
        >
          Why use PeaceTwogether?
          <br /> It’s Anonymous, Secured & Awesome
        </Typography>
      </Stack>
      <Stack
        spacing={5}
        justifyContent={"center"}
        marginTop={-30}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
      >
        {[
          {
            logo: Chat,
            title: "Easy Buddy",
            content:
              "AI powered virtual assistant for personalized mental wellness companion for support & help in need.",
          },
          {
            logo: Video,
            title: "Anonymous Call",
            height: 60,
            content:
              "Talk your heart out. Anonymously Vent, Relieve Stress, and Find Inner Peace Anywhere, Anytime.",
          },
          {
            logo: Professionals,
            title: "Professionals",
            content:
              "Compassionate Guidance: Empowering Your Mental Wellness Journey with Expert Support, Step by Step.",
          },
        ].map((item, index) => {
          return (
            <Paper
              key={index}
              onClick={() =>
                navigate(
                  localStorage.getItem("token") ? "/v2/client/home" : "/login"
                )
              }
              sx={{
                borderRadius: 10,
                backgroundColor: "#FBFBFB",
                boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: 4,
                cursor: "pointer",

                transition: "0.3s",
                "&:hover": {
                  // boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  // transform: "scale(1.1)",
                  transform: "translateY(-8px)", // Adjust the value as desired
                },
              }}
            >
              <Stack spacing={2} width={{ lg: 240 }} alignItems={"center"}>
                <img
                  src={item.logo}
                  alt=""
                  height={item.height ?? 70}
                  width={70}
                />
                <Typography fontWeight={700} color="#42445D" variant="h5">
                  {item.title}
                </Typography>
                <Typography color="#42445D" textAlign={"center"}>
                  {item.content}
                </Typography>
                <Stack
                  paddingTop={2}
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  sx={{ alignSelf: "end" }}
                >
                  <Typography fontWeight={700}>Learn More</Typography>
                  <img alt="" src={Arrow} width={16} height={16} />
                </Stack>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
      <Stack
        flexDirection={{ lg: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        paddingTop={{ lg: 20, xs: 10 }}
        spacing={5}
      >
        <Stack spacing={2} maxWidth={{ lg: "36vw" }}>
          <Typography
            fontWeight={700}
            color="#42445D"
            fontSize={{ lg: 40, md: 40, sm: 40, xs: 30 }}
          >
            EasyBuddy, Your virtual Wellness Assistance
          </Typography>
          <Typography py={2}>
            An AI-Powered Personalized virtual Assistant; Your Empathetic,
            Intelligent, and Supportive Wellness Companion, Engineered to
            Understand Your Feelings Guide You with Care.
          </Typography>
          <EasyButton
            onClick={() =>
              localStorage.getItem("token")
                ? navigate("/v2/client/easyBuddy")
                : setOpenEasyBuddy(true)
            }
            label={"Chat with EasyBuddy"}
            width="240px"
            size="small"
          />
          <Button
            onClick={() =>
              navigate(
                localStorage.getItem("token") ? "/v2/client/home" : "/login"
              )
            }
            variant="outlined"
            sx={{
              color: "#02033B",
              borderColor: "#02033B",
              textTransform: "none",
              fontWeight: "bold",
              width: "180px",
              borderRadius: 10,
              borderWidth: 2,
            }}
          >
            Call Anonymously
          </Button>
        </Stack>
        <Box
          component="img"
          alt="Easy Buddy"
          src={ChatLady}
          sx={{
            width: {
              xs: "90%",
              md: "445px",
            },
          }}
        />
      </Stack>
      <Stack
        paddingTop={{ lg: 20, xs: 10 }}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        alignItems={"center"}
      >
        <Typography
          fontWeight={700}
          color="#42445D"
          variant="h3"
          gutterBottom
          // fontSize={{ lg: 45, md: 28, sm: 30, xs: 30 }}
          textAlign={{ lg: "center" }}
        >
          Empowering Minds, Nurturing Wellness
          <br /> Your Journey Starts Here
        </Typography>
        <Typography textAlign={{ lg: "center" }} variant="h6" paddingBottom={5}>
          Discover a range of compassionate services, from counseling to
          holistic well-being programs, guiding you towards emotional balance,
          resilience, and lasting inner peace.
        </Typography>
        <Stack
          flexDirection={"row"}
          paddingTop={5}
          spacing={10}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={10}
          sx={{
            "& > *:not(:first-of-type)": {
              marginTop: "0", // Setting the top margin to 0
            },
          }}
        >
          {[
            {
              img: Service1,
              title: "Stay Anonymous",
              content:
                "Private, anonymous sharing for support and guidance without judgement.",
            },
            {
              img: Service2,
              title: "Supportive AI Companion",
              content:
                "AI-driven wellness support for your mental health or Legal assistance.",
            },
            {
              img: Service3,
              title: "Peer Group Support",
              content:
                "Peer encouragement fosters community strength and understanding.",
            },
            {
              img: Service4,
              title: "Connect to Professionals",
              content:
                "Connect with professionals for personalized mental Wellness guidance.",
            },
            {
              img: Service5,
              title: "Write Mood Journals",
              content:
                "Reflect and grow through mood journaling and self-awareness.",
            },
            {
              img: Service6,
              title: " Personality Analysis",
              content:
                "Discover your unique traits through comprehensive personality assessments.",
            },
          ].map((item, index) => {
            return (
              <Stack key={index} spacing={1} width={300}>
                <img src={item.img} alt="" width={300} height={330} />
                <Typography fontWeight={700} variant="h5">
                  {item.title}
                </Typography>
                <Typography>{item.content}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        textAlign={{ lg: "center" }}
        pt={{ lg: 10, md: 10, sm: 5, xs: 5 }}
        pb={{ lg: 3, md: 3, sm: 3, xs: 3 }}
      >
        <Typography
          fontSize={{ lg: 45, md: 40, sm: 40, xs: 30 }}
          color="#050038"
          fontWeight={"bold"}
          gutterBottom
        >
          Navigate Your Path with Certainty and Assurance
        </Typography>
        <Typography fontSize={{ lg: 25, md: 20, sm: 20, xs: 20 }}>
          Empowering you with clear guidance, leading you towards resolutions,
          and providing a steadfast shield through legal or Relationship
          complexities.
        </Typography>
      </Stack>
      <Stack
        flexDirection={{ lg: "row" }}
        alignItems={"start"}
        justifyContent={"space-between"}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        // paddingTop={{ lg: 20, xs: 10 }}
        spacing={5}
      >
        <Box
          component="img"
          alt="Legal Buddy"
          src={legalBuddy}
          sx={{
            marginTop: "40px",
            width: {
              xs: "90%",
              md: "425px",
            },
          }}
        />
        <Stack spacing={2} maxWidth={{ lg: "36vw" }} sx={{}}>
          <Typography
            fontWeight={700}
            color="#42445D"
            fontSize={{ lg: 40, md: 40, sm: 40, xs: 30 }}
          >
            Legal Buddy, Your Personal Legal Assistance
          </Typography>
          <Typography py={1}>
            Meet your dedicated Legal Buddy, Your reliable partner, offering
            personalized assistance, expert advice, and unwavering support on
            your legal journey, Relationship difficulties every step of the way.
          </Typography>
          <EasyButton
            onClick={() =>
              navigate(
                localStorage.getItem("token") ? "/v2/client/home" : "/login"
              )
            }
            label={"Chat with Legal Buddy"}
            width="300px"
            size="small"
          />
          <Button
            onClick={() =>
              navigate(
                localStorage.getItem("token") ? "/v2/client/home" : "/login"
              )
            }
            variant="outlined"
            sx={{
              color: "#02033B",
              borderColor: "#02033B",
              textTransform: "none",
              fontWeight: "bold",
              width: "180px",
              borderRadius: 10,
              borderWidth: 2,
            }}
          >
            Call Anonymously
          </Button>
        </Stack>
      </Stack>
      <Stack
        flexDirection={"row"}
        // spacing={10}
        alignItems={"center"}
        gap={1}
        justifyContent={"center"}
        pt={20}
        pb={10}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
      >
        <Typography
          fontSize={{ lg: 40, md: 30, sm: 30, xs: 30 }}
          textAlign={"center"}
        >
          Wellness Professionals Live
        </Typography>
        <img
          alt=""
          src={live}
          height={"20px"}
          width={"20px"}
          style={{ marginTop: 0 }}
        />
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={{ lg: 8, md: 5, sm: 5, xs: 5 }}
        spacing={{ lg: 8, md: 5, sm: 5, xs: 5 }}
        flexWrap={"wrap"}
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        pb={5}
        sx={{
          "& > *:not(:first-of-type)": {
            marginTop: "0", // Setting the top margin to 0
          },
        }}
      >
        {consultants.map((item, index) => {
          return <LiveConsultantCard consultant={item} key={index} />;
        })}
      </Stack>
      <LandingFooter />
      {/* <Dialog
        onClose={(event, reason) => {
          if (reason === "backdropClick") setOpenSupport(false);
        }}
        open={openSupport}
        sx={{}}
        maxWidth="md"
      >
        <DialogContent>
          <div
            style={{
              overflow: "hidden",
              // width: "200px",
              height: "100px",
              position: "relative",
            }}
          >
            <iframe
              title="lottie"
              style={{
                border: "none",
                // width: "200px",
                height: "200px", // Original height of the content
                position: "absolute",
                top: "50%", // Move the top edge to the center of the container
                left: "50%", // Move the left edge to the center of the container
                transform: "translateY(-50%) translateX(-50%)",
              }}
              src="https://lottie.host/embed/c13e72ac-a561-4a40-96fa-3e1c1bde51c0/SqvWdbi2q7.json"
            ></iframe>
          </div>
          <DialogContentText sx={{ paddingBottom: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              Contact Easy Guide
            </Typography>
            <Typography variant="body1">
              We are here to help. Ask us anything.
            </Typography>
          </DialogContentText>
          <EasyButton2
            label={"Call Easy Guide"}
            onClick={() => window.open(`tel:${helpLine}`)}
          />
        </DialogContent>
        <Stack sx={{ padding: 0 }} direction={{ md: "row" }}></Stack>
      </Dialog> */}

      <Dialog
        onClose={(event, reason) => {
          if (reason === "backdropClick") setOpenEasyBuddy(false);
        }}
        open={openEasyBuddy}
        sx={{}}
        maxWidth="md"
        // fullWidth
      >
        <Stack
          sx={{ padding: 0 }}
          direction={{ md: "row" }}
          alignItems={"center"}
          justifyContent={"space-around"}
        >
          <Stack>
            <Box
              component="img"
              alt="Easy Buddy"
              src={penguin}
              sx={{
                width: {
                  xs: "100%",
                  md: "400px",
                },
              }}
            />
          </Stack>
          <Stack p={1}>
            {" "}
            <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
              Easy Buddy Beta
            </Typography>
            <Typography variant="body1" color={"grey"} textAlign={"center"}>
              Your AI mental wellbeing companion
            </Typography>
            <Typography
              variant="body1"
              color={"grey"}
              gutterBottom
              textAlign={"center"}
              maxWidth={"400px"}
            >
              FREE with every PeaceTwogether account
            </Typography>
            <Stack alignItems={"self-start"} pt={1}>
              {[
                "Anonymously chatting",
                "Instant support",
                "Assessment & screening",
                "Self help techniques",
                "Education & information",
                "Crisis Intervention",
                "Personalized Recommendation",
              ].map((e, index) => {
                return (
                  <Stack
                    key={index}
                    direction={"row"}
                    justifyContent={"start"}
                    spacing={2}
                    padding={0.5}
                  >
                    <DoneIcon /> <Typography variant="body2"> {e}</Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Stack pt={2} direction={"row"} spacing={1}>
              <EasyButton2
                label="SignUp"
                size="small"
                width={{ md: "180px", xs: "100%" }}
                onClick={() => navigate("/signup")}
              ></EasyButton2>
              <EasyButton2
                label="Login"
                size="small"
                width={{ md: "180px", xs: "100%" }}
                onClick={() => navigate("/login")}
              ></EasyButton2>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
}
