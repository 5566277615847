import Stack from "@mui/material/Stack";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import { useEffect, useState } from "react";
import EasyButton2 from "../../../components/v2/easyButton2";
import Panel from "../../../components/v2/panel";
import { panelPageMargin } from "../../../utils/consts";
export default function NewAddReview() {
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(5);
  const [error, setError] = useState("");
  let { meetingId } = useParams();
  const navigate = useNavigate();

  const addReview = async () => {
    try {
      if (!review) {
        setError("Review cannot be empty");
        return;
      }
      if (!rating) {
        setError("Rating cannot be empty");
        return;
      }
      setError("");
      await axios.post(
        `${BaseURL}/api/client/addReview`,
        {
          meetingId: meetingId,
          rating,
          message: review,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setOpen(true);
    } catch (err) {
      setError(err.response?.data?.message ?? err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchReview = async () => {
      try {
        let res = await axios.get(
          `${BaseURL}/api/client/getReview/${meetingId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        if (res.data) {
          setReview(res.data?.message);
          setRating(res.data?.rating);
        }
      } catch (err) {
        console.log(err.message);
        setError(err.response?.data?.message ?? err.message);
      }
    };
    fetchReview();
  }, [meetingId]);

  return (
    <Stack
    sx={{...panelPageMargin}}
      spacing={5}
      paddingX={{ md: 10, xs: 2 }}
    >
      <Panel />
      <h2>Add Review for Consultant</h2>
      <Stack>
        <TextField
          multiline
          minRows={5}
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Tell us all about your experience and how we can improve it"
        ></TextField>
        <Rating
          size={"large"}
          value={rating}
          sx={{ paddingY: 3 }}
          onChange={(e) => setRating(parseFloat(e.target.value))}
        ></Rating>
        {error && (
          <Alert sx={{ marginY: 5 }} severity="error">
            {error}
          </Alert>
        )}
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={5}>
          <EasyButton2
            label="Cancel"
            width="140px"
            onClick={() => navigate("/v2/client/home")}
          ></EasyButton2>
          <EasyButton2
            label="Submit"
            width="140px"
            onClick={addReview}
          ></EasyButton2>
        </Stack>
      </Stack>
      <Dialog open={open}>
        <DialogTitle>Review Submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for your valuable feedback. Have a nice day !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label="Thanks"
            onClick={() => navigate("/v2/client/home")}
            width="150px"
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
