import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Stack, TextField } from "@mui/material";
import EasyButton2 from "../../components/v2/easyButton2";
import signupPic from "../../utils/signup.png";
import tick from "../../utils/tick.png";
import axios from "axios";
import { BaseURL, validateEmail, validatePhoneNo } from "../../utils/consts";
import { useState, cloneElement, useRef } from "react";
import Logo from "../../components/logo";
import Captcha from "../../components/captcha";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function CorporateSignUp() {
  const [company, setCompany] = useState({
    name: "",
    phoneNo: "",
    email: "",
    adminName: "",
    noOfEmployee: "",
    requiredCover: "",
    gstNo: "",
    address: "",
    location: "",
    remark: "",
  });
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const openCaptcha = () => {
    setError("");
     if (!company.name) {
       setError("Company Name is required");
       return;
     }
     if (!company.phoneNo) {
       setError("Phone no. is required");
       return;
     }
     if (!company.email) {
       setError("Email is required");
       return;
     }
     if (!validateEmail(company.email)) {
       setError("Invalid Email");
       return;
     }
     if (!validatePhoneNo(company.phoneNo)) {
       setError("Invalid Phone no.");
       return;
     }
     if (!company.adminName) {
       setError("Admin name is required");
       return;
     }
    if (captchaRef.current) {
      captchaRef.current.execute();
    }
  };

  const submit = async (captchaToken) => {
    try {
      setError("");
      await axios.post(`${BaseURL}/api/corporate/auth/register`, {
        ...company,
        captchaToken,
      });
      setOpen(true);
    } catch (err) {
      console.log(err.message);
      if (err.response?.data?.message) setError(err.response.data.message);
      else setError(err.message);
    }
  };

  return (
    <>
      <ElevationScroll>
        <AppBar
          sx={{
            bgcolor: "white",
            paddingX: { md: 10, lg: 20 },
            paddingY: { lg: 1 },
          }}
        >
          <Toolbar>
            <Logo />
            <Button
              variant="contained"
              type="button"
              sx={{
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
                marginLeft: "auto",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#662D91", // adjust as needed for hover effect
                },
              }}
              onClick={() => navigate("/corporate/landing")}
            >
              Corporate Pro
            </Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {!open ? (
        <Stack
          direction={{ md: "row" }}
          justifyContent={"space-between"}
          spacing={{ lg: 20, md: 15, xs: 5 }}
          padding={{ lg: 20 }}
          px={{ xs: 2, md: 10 }}
          py={{ xs: 10, md: 15 }}
        >
          <Stack
            width={{ lg: "50%" }}
            spacing={5}
            px={{ lg: 5 }}
            alignContent={"center"}
          >
            <img
              alt="Corporate Signup"
              src={signupPic}
              style={{ height: "450px", width: "340px", alignSelf: "center" }}
            />
            <Typography
              padding={{ lg: 5 }}
              variant="h6"
              textAlign={"center"}
              sx={{ fontFamily: "Roboto,sans-serif", fontWeight: 500 }}
            >
              Setting an example by prioritizing the importance of mental
              well-being in the workplace, leading the industry.
            </Typography>
            {[
              "Personalized Mental Well being Journey",
              "Supportive AI Companion & Wellness Mentor",
              "Real time Wellness Score Monitoring",
              "Expert Consultant Finder & Annonimity",
              "Anonymous Mood Journaling",
              "Holistic Progress Report",
              "Corporate Conflict Resolution",
            ].map((item, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                key={index}
                paddingY={0}
                spacing={2}
              >
                <img alt="Tick" src={tick} width={30} height={30} />
                <Typography sx={{ fontWeight: 540 }} variant="h6">
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack spacing={5} width={{ lg: "50%" }}>
            <Typography variant="h4" fontWeight={"bold"}>
              Empower with Care, Nurturing Success, Strengthening Teams
            </Typography>
            <Typography variant="h6" fontWeight={"medium"}>
              The businesses are transforming to support employees and families.
              Fill the form and get an early access to the platform.
            </Typography>
            <TextField
              id="name"
              name="name"
              autoComplete="off"
              label="Company Name"
              value={company.name}
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
              variant="outlined"
            />{" "}
            <TextField
              id="gstNo"
              name="gstNo"
              label="GST Number"
              value={company.gstNo}
              onChange={(e) =>
                setCompany({ ...company, gstNo: e.target.value.trim() })
              }
              variant="outlined"
            />{" "}
            <TextField
              id="address"
              name="address"
              autoComplete="off"
              label="Company Address"
              value={company.address}
              onChange={(e) =>
                setCompany({ ...company, address: e.target.value })
              }
              variant="outlined"
            />
            <TextField
              id="location/city"
              name="location/city"
              label="Company Location/City"
              variant="outlined"
              value={company.location}
              onChange={(e) =>
                setCompany({ ...company, location: e.target.value })
              }
            />
            <TextField
              id="adminName"
              name="adminName"
              label="Admin Name"
              variant="outlined"
              value={company.adminName}
              onChange={(e) =>
                setCompany({ ...company, adminName: e.target.value })
              }
            />
            <TextField
              id="email"
              name="email"
              autoComplete="off"
              label="Admin Email ID"
              variant="outlined"
              value={company.email}
              onChange={(e) =>
                setCompany({ ...company, email: e.target.value.trim() })
              }
            />
            <TextField
              id="phoneNo"
              name="phoneNo"
              label="Admin Phone Number"
              variant="outlined"
              value={company.phoneNo}
              onChange={(e) =>
                setCompany({ ...company, phoneNo: e.target.value.trim() })
              }
            />
            <TextField
              id="cover"
              name="cover"
              label="Total no of cover required"
              variant="outlined"
              value={company.requiredCover}
              onChange={(e) =>
                setCompany({ ...company, requiredCover: e.target.value })
              }
            />
            <TextField
              id="noOfEmployee"
              name="noOfEmployee"
              label="Total no of employee if known"
              variant="outlined"
              value={company.noOfEmployee}
              onChange={(e) =>
                setCompany({ ...company, noOfEmployee: e.target.value })
              }
            />
            <TextField
              id="message"
              name="message"
              label="Remark if any"
              variant="outlined"
              multiline
              value={company.remark}
              onChange={(e) =>
                setCompany({ ...company, remark: e.target.value })
              }
            />{" "}
            <Captcha
              captchaRef={captchaRef}
              onCaptchaSuccess={(token) => {
                submit(token);
              }}
            />
            {error && <Alert severity="error">{error}</Alert>}
            <EasyButton2 size="small" label="Sign Up" onClick={openCaptcha} />
          </Stack>
        </Stack>
      ) : (
        <Card
          sx={{
            height: "60vh",
            marginTop: { lg: 20, xs: 10 },
            marginBottom: 2,
            marginX: { lg: 10, xs: 2 },

            paddingX: { lg: 20, xs: 2 },
            paddingY: 5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Checkmark"
            src={tick}
            width={30}
            height={30}
            style={{ alignSelf: "center" }}
          />
          <Typography
            variant={"h4"}
            paddingY={5}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Thank you for your interest in the PeaceTwogether Corporate Plan!
            We've received your message and will get in touch soon. Stay tuned!
          </Typography>
          <EasyButton2
            label="Home"
            width={"250px"}
            onClick={() => navigate("/corporate/landing")}
          />
        </Card>
      )}
      <Stack
        direction={{ md: "row" }}
        alignItems={{
          lg: "flex-end",
          md: "flex-end",
          sm: "center",
          xs: "center",
        }}
        sx={{
          justifyContent: "space-between",
          marginBottom: 5,
          paddingX: { xs: 2, md: 10, lg: 20 },
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "flex-start" }}
          height={"100px"}
          spacing={2}
        >
          <Logo />
          <Typography color={"rgba(0, 0, 0, 0.57)"}>
            ©Copyright 2023 | Virtue Mind Pvt. Ltd
          </Typography>
        </Stack>

        <Button
          variant="text"
          color="inherit"
          type="button"
          onClick={() => navigate("/corporate/t&c")}
          sx={{ color: "rgba(0, 0, 0, 0.57)", textTransform: "none" }}
        >
          Terms & Conditions
        </Button>
      </Stack>
    </>
  );
}
