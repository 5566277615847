import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import loginPic from "../../utils/namaste.png";
import axios from "axios";
import { BaseURL, validateEmail, validatePhoneNo } from "../../utils/consts";
import { useState, cloneElement, useRef } from "react";
import PasswordTextfield from "../../components/passwordTextfield";
import { Loader } from "../../components/Loader";
import { MuiOtpInput } from "mui-one-time-password-input";
import EasyButton2 from "../../components/v2/easyButton2";
import Logo from "../../components/logo";
import Captcha from "../../components/captcha";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function CorporateLogin() {
  const [company, setCompany] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [forgotDialog, setForgotDialog] = useState(0);
  const [phoneNo, setPhoneNo] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const otpCaptchaRef = useRef(null);
  const openCaptcha = () => {
    setError("");
    setError("");
    if (!company.username) {
      setError("Email/Phone  no. is required");
      return;
    }
    if (!company.password) {
      setError("Password is required");
      return;
    }
    if (captchaRef.current) {
      captchaRef.current.execute();
    }
  };

  const openOtpCaptcha = () => {
    if (!validatePhoneNo(phoneNo)) return setError("Invalid Phone no.");

    if (otpCaptchaRef.current) {
      otpCaptchaRef.current.execute();
    }
  };
  const login = async (captchaToken) => {
    try {
      const isEmail = validateEmail(company.username);
      const isPhoneNo = validatePhoneNo(company.username);
      if (!isEmail && !isPhoneNo) {
        setError("Invalid Email/Phone no.");
        return;
      }
      setLoading(true);

      let res = await axios.post(
        `${BaseURL}/api/corporate/auth/login`,
        isEmail
          ? { ...company, email: company.username, captchaToken }
          : {
              ...company,
              phoneNo: company.username,
              captchaToken,
            }
      );
      localStorage.setItem("corporateToken", res.data.token);
      localStorage.setItem("corporateName", res.data.name);
      localStorage.setItem("corporateAdminName", res.data.adminName);
      navigate("/corporate/dashboard", { replace: true });
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  const sendOtp = async (captchaToken) => {
    try {
      setLoading(true);
      setError("");
      setOtp("");
      await axios.post(`${BaseURL}/api/corporate/auth/sendForgotPasswordOtp`, {
        phoneNo,
        captchaToken,
      });
      setLoading(false);
      setForgotDialog(3);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.response?.data?.message) setError(e.response.data.message);
      else setError(e.message);
    }
  };

  const otpVerify = async (otpValue) => {
    try {
      setLoading(true);
      setError("");
      let res = await axios.post(
        `${BaseURL}/api/corporate/auth/verifyForgotPasswordOtp`,
        { otp: otpValue, phoneNo: phoneNo }
      );
      setLoading(false);
      localStorage.setItem("corporateToken", res.data.token);
      localStorage.setItem("corporateName", res.data.name);
      localStorage.setItem("corporateAdminName", res.data.adminName);
      setForgotDialog(4);
    } catch (e) {
      console.log(e);
      if (e.response?.data?.message) setError(e.response.data.message);
      else setError(e.message);
      setOtp("");
      setLoading(false);
    }
  };

  const passwordReset = async () => {
    try {
      if (password.length < 6) {
        setError("Password must be atleast 6 characters long");
        return;
      }
      if (confirmPassword !== password) {
        setError("Password re-entry not matching");
        return;
      }
      setLoading(true);
      setError("");
      await axios.post(
        `${BaseURL}/api/corporate/auth/resetPassword`,
        { password },
        {
          headers: {
            "x-auth-token": localStorage.getItem("corporateToken"),
          },
        }
      );
      setLoading(false);
      setForgotDialog(5);
    } catch (e) {
      console.log(e);
      if (e.response?.data?.message) setError(e.response.data.message);
      else setError(e.message);
    }
  };

  return (
    <>
      <ElevationScroll>
        <AppBar
          sx={{
            bgcolor: "white",
            paddingX: { xs: 5, lg: 25 },
            paddingY: { lg: 1 },
          }}
        >
          <Toolbar>
            <Logo />
            <Button
              variant="contained"
              type="button"
              sx={{
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
                marginLeft: "auto",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#662D91", // adjust as needed for hover effect
                },
              }}
              onClick={() => navigate("/corporate/landing")}
            >
              Corporate Pro
            </Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Stack
        direction={{ lg: "row" }}
        justifyContent={"space-between"}
        spacing={{ lg: 20, xs: 5 }}
        padding={{ lg: 20 }}
        px={{ xs: 2 }}
        py={{ xs: 10 }}
      >
        <Stack
          width={{ lg: "50%" }}
          spacing={5}
          px={{ lg: 5 }}
          alignContent={"center"}
        >
          <img
            alt="Login pic"
            src={loginPic}
            style={{ height: "450px", width: "340px", alignSelf: "center" }}
          />
          <Typography
            padding={{ lg: 5 }}
            variant="h6"
            textAlign={"center"}
            sx={{ fontFamily: "Roboto,sans-serif", fontWeight: 500 }}
          >
            Welcome! Shaping the future of work by showcasing the paramount
            importance of mental well-being and taking the lead in the industry.
          </Typography>
        </Stack>
        <Stack spacing={5} width={{ lg: "50%" }}>
          <h1>Corporate Admin Login</h1>
          <Typography variant="h4" fontWeight={"bold"}>
            Empower with Care, Nurturing Success, Strengthening Teams
          </Typography>
          <Typography variant="h6" fontWeight={"medium"}>
            The businesses are transforming to support employees and families.
          </Typography>
          <TextField
            label="Enter email/phoneNo"
            autoComplete="off"
            name="jhgvff"
            value={company.username}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setCompany({ ...company, username: e.target.value.trim() })
            }
            variant="outlined"
          />{" "}
          <PasswordTextfield
            label="Enter password"
            password={company.password}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            setPassword={(password) => setCompany({ ...company, password })}
            login={openCaptcha}
          />
          <Button
            sx={{ alignSelf: "flex-end", color: "#662D91" }}
            onClick={() => setForgotDialog(1)}
          >
            Forgot Password
          </Button>{" "}
          <Captcha
            captchaRef={captchaRef}
            onCaptchaSuccess={(token) => {
              login(token);
            }}
          />
          <Captcha
            captchaRef={otpCaptchaRef}
            onCaptchaSuccess={(token) => {
              sendOtp(token);
            }}
          />
          {error && <Alert severity="error">{error}</Alert>}
          {loading && <Loader height={10} />}
          <EasyButton2 label="Login" onClick={openCaptcha} />
        </Stack>
      </Stack>
      <Stack
        direction={{ md: "row" }}
        alignItems={{
          lg: "flex-end",
          md: "flex-end",
          sm: "center",
          xs: "center",
        }}
        sx={{
          justifyContent: "space-between",
          marginBottom: 5,
          paddingX: { xs: 5, lg: 25 },
        }}
      >
        <Stack justifyContent={"center"} height={"100px"} spacing={-2}>
          <Logo />
          <Typography color={"rgba(0, 0, 0, 0.57)"}>
            ©Copyright 2023 | Virtue Mind Pvt. Ltd
          </Typography>
        </Stack>

        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate("/corporate/t&c")}
          sx={{ color: "rgba(0, 0, 0, 0.57)", textTransform: "none" }}
        >
          Terms & Conditions
        </Button>
      </Stack>
      <Dialog open={forgotDialog === 1} onClose={() => setForgotDialog(0)}>
        <DialogTitle>Forgot Password ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reset password through otp verification of phone number
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={"150px"}
          ></EasyButton2>{" "}
          <EasyButton2
            label={"Continue"}
            width={"150px"}
            onClick={() => {
              setForgotDialog(2);
            }}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 2}>
        <DialogTitle>Enter Phone number</DialogTitle>
        <DialogContent>
          {loading ? (
            <Loader height={"auto"} />
          ) : (
            <TextField
              label="Enter phone no"
              fullWidth
              type={"tel"}
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value.trim())}
              variant="outlined"
              margin="normal"
            />
          )}{" "}
          {error !== "" && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={"150px"}
          ></EasyButton2>{" "}
          <EasyButton2
            label={"Continue"}
            width={"150px"}
            onClick={openOtpCaptcha}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 3} fullWidth>
        <DialogTitle variant="h5" textAlign={"center"}>
          Please enter one time password to verify your account
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            Code has been sent to +91 {phoneNo}
          </DialogContentText>
          <Stack alignItems={"center"} m={{ lg: 5, xs: 0 }} my={5}>
            {loading ? (
              <Loader height={"auto"} />
            ) : (
              <MuiOtpInput
                autoFocus
                sx={{
                  gap: { xs: 0.5, md: 2 },
                  minWidth: "250px",
                  marginInline: "auto",
                  display: "flex",
                  maxWidth: "650px",
                  justifyContent: "center",
                  paddingY: { md: 0, xs: 2 },
                }}
                length={6}
                value={otp}
                onChange={(e) => {
                  setOtp(e);
                  setError("");
                }}
                onComplete={(e) => otpVerify(e)}
                TextFieldsProps={{
                  type: "number",
                  inputProps: {
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  },
                }}
              />
            )}{" "}
          </Stack>
          <div styles={{ fontSize: "22px" }}>
            Didn't receive sms? <Button onClick={openOtpCaptcha}>Resend Otp</Button>
          </div>
          {error !== "" && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={"150px"}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 4}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {loading ? (
            <Loader height={"auto"} />
          ) : (
            <Stack>
              <PasswordTextfield
                password={password}
                setPassword={(e) => setPassword(e)}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
              <PasswordTextfield
                password={confirmPassword}
                setPassword={(e) => setConfirmPassword(e)}
                showPassword={showPassword}
                label="Confirm password"
                setShowPassword={setShowPassword}
              />
              {error !== "" && <Alert severity="error">{error}</Alert>}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={"150px"}
          ></EasyButton2>{" "}
          <EasyButton2
            label={"Continue"}
            width={"150px"}
            onClick={passwordReset}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 5}>
        <DialogTitle>Password Updated</DialogTitle>
        <DialogContent>Password update successful !</DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Ok"}
            onClick={() => {
              setForgotDialog(0);
              navigate("/corporate/dashboard", { replace: true });
            }}
            width={"150px"}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </>
  );
}
