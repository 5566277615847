import {
  Alert,
  Box,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import HelpCenterPic from "../../../utils/helpCenter.png";
import Location from "../../../utils/location.png";
import Phone from "../../../utils/phone.png";
import Mail from "../../../utils/mail.png";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import axios from "axios";
import { BaseURL, helpLine, validateEmail } from "../../../utils/consts";
import EasyButton2 from "../../../components/v2/easyButton2";

export default function HelpCenter() {
  const [query, setQuery] = useState({ name: "", email: "", message: "" });
  const [error, setError] = useState("");

  const submitQuery = async () => {
    try {
      setError("");
      setQuery({ ...query, success: false });
      if (!query.name) {
        setError("Name is required");
        return;
      }
      if (!validateEmail(query.email)) {
        setError("Valid Email is required");
        return;
      }
      if (!query.message) {
        setError("Message is required");
        return;
      }
      await axios.post(`${BaseURL}/api/client/settings/helpCenter`, query, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setQuery({ success: true, name: "", email: "", message: "" });
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Panel />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 3,
          marginTop: 2,
        }}
      >
        <Toolbar />
        <Stack
          direction={{ lg: "row" }}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={10}
          gap={5}
          padding={{ lg: 5, xs: 0 }}
        >
          <Paper sx={{ padding: { lg: 5, xs: 2 }, borderRadius: 2 }}>
            <Stack spacing={5} justifyContent={"center"} alignItems={"center"}>
              <img
                alt=""
                src={HelpCenterPic}
                height={"auto"}
                width={"100%"}
                style={{ borderRadius: 5 }}
              />
              <Stack spacing={2}>
                <a href={`tel:${helpLine}`}>
                  <Stack direction={"row"} spacing={2}>
                    <img alt="" src={Phone} height={20} width={20} />
                    <Typography fontWeight={"bold"}>{helpLine}</Typography>
                  </Stack>
                </a>
                <a href="mailto:support@peacetwogether.com">
                  <Stack direction={"row"} spacing={2}>
                    <img alt="" src={Mail} height={20} width={20} />
                    <Typography>support@peacetwogether.com</Typography>
                  </Stack>
                </a>
                <Stack direction={"row"} spacing={2}>
                  <img alt="" src={Location} height={20} width={20} />
                  <Typography>
                    23/A/V/S D.H. Road, Flat-A, 4th Floor, Kolkata, West Bengal,
                    India, 700053
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
          <Stack justifyContent={"center"} paddingBottom={2}>
            <Typography color={"#662D91"} variant="h4" fontWeight={"bold"}>
              Get in Touch
            </Typography>
            <Typography paddingBottom={5}>
              Any question about Platform, Sales, Meeting or remarks? Let us
              know!
            </Typography>
            <TextField
              id="name"
              name="Name"
              label="Name"
              variant="outlined"
              autoComplete="off"
              value={query.name}
              onChange={(e) => setQuery({ ...query, name: e.target.value })}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              id="email"
              name="Email"
              label="Email"
              variant="outlined"
              autoComplete="off"
              value={query.email}
              onChange={(e) =>
                setQuery({ ...query, email: e.target.value.trim() })
              }
              sx={{ marginBottom: 2 }}
            />{" "}
            <TextField
              id="message"
              name="message"
              autoComplete="off"
              sx={{ marginBottom: 2 }}
              multiline
              minRows={4}
              placeholder="Enter your message here"
              variant="outlined"
              value={query.message}
              onChange={(e) => setQuery({ ...query, message: e.target.value })}
            />
            <EasyButton2 label="Submit" size="small" onClick={submitQuery} />
            {error && (
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {error}
              </Alert>
            )}
            {query.success && (
              <Alert severity="success" sx={{ marginTop: 2 }}>
                Thank you! Message Received. Will get back to you soon
              </Alert>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
