import { Paper, Stack, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import CustomPieChart from "./pieChart";
const emotionMap = {
  Angry: { emoji: "😡", color: "#FFB6A6" },
  Confused: { emoji: "🤨", color: "#FFCF55" },
  Sad: { emoji: "😕", color: "#FFF731" },
  Neutral: { emoji: "🙂", color: "#00FFFF" },
  Happy: { emoji: "😊", color: "#29E259" },
};

export default function MoodChart({ data }) {
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={5}
      padding={{ xs: 3, md: 10 }}
      justifyContent={"center"}
    >
      <Paper
        sx={{
          backgroundColor: "whitesmoke",
          padding: 3,
          borderRadius: "10px",
        }}
        elevation={0}
      >
        <CustomPieChart
          title={""}
          colors={data.map((d) => emotionMap[d.name].color)}
          data={data}
        />
        <Typography
          variant="h6"
          gutterBottom
          fontWeight={"bold"}
          textAlign={"center"}
          mt={4}
        >
          Emotion Distribution
        </Typography>
      </Paper>
      <Paper
        sx={{
          backgroundColor: "whitesmoke",
          padding: 3,
          borderRadius: "10px",
          width: "100%",
        }}
        elevation={0}
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" fontSize={10} />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={emotionMap[entry.name].color}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Typography
          variant="h6"
          gutterBottom
          fontWeight={"bold"}
          textAlign={"center"}
        >
          Mood Analysis
        </Typography>
      </Paper>
    </Stack>
  );
}
