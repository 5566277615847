import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function Captcha({ captchaRef, onCaptchaSuccess }) {

  return (
    <form>
      <HCaptcha
        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
        render="explicit"
        onVerify={onCaptchaSuccess}
        ref={captchaRef}
        size="invisible"
      />
    </form>
  );
}
