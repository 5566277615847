import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import EasyButton2 from "../../../components/v2/easyButton2";
import FeedbackImg from "../../../utils/feedback.jpg";
import { useEffect } from "react";
import moment from "moment";

export default function Feedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [newFeedback, setNewFeedback] = useState({
    title: "",
    content: "",
    open: false,
    isSubmitted: false,
  });
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      let res = await axios.get(`${BaseURL}/api/client/journals/feedbacks`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setFeedbacks(res.data);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
    }
  };
  const submitFeedback = async () => {
    try {
      if (!newFeedback.title) {
        setError("Title is required");
        return;
      }
      if (!newFeedback.content) {
        setError("Content is required");
        return;
      }
      const corporate = localStorage.getItem("company");

      let res = await axios.post(
        `${BaseURL}/api/client/journals/submitFeedback`,
        { ...newFeedback, corporate },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setFeedbacks([...feedbacks, res.data.newFeedback]);
      setNewFeedback({ title: "", content: "", isSubmitted: true });
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Panel />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 3,
          marginTop: 2,
        }}
      >
        <Toolbar />
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "stretch", sm: "center" }}
        >
          <h2>My Corporate Feedbacks</h2>
          <EasyButton2
            label="Submit Feedback"
            onClick={() =>
              setNewFeedback({ title: "", content: "", open: true })
            }
            width={200}
          />
        </Stack>
        <Stack spacing={2}>
          {feedbacks.length === 0 && (
            <Stack alignItems={"center"} spacing={2}>
              <Typography variant="h6" color={"textSecondary"}>
                No Feedbacks yet
              </Typography>
              <EasyButton2
                label="Write A Feedback"
                width={200}
                onClick={() =>
                  setNewFeedback({ title: "", content: "", open: true })
                }
              />
            </Stack>
          )}
          {feedbacks.map((feedback) => (
            <Paper key={feedback._id}>
              <Stack sx={{ paddingX: 3, paddingY: 3 }}>
                <Typography color={"textSecondary"} variant="body2">
                  {" "}
                  {moment(feedback.timeStamp).format("h:mm A, DD/MM/YY")}
                </Typography>
                <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                  {feedback.title}
                </Typography>
                <Typography variant="body1">{feedback.content}</Typography>
              </Stack>
            </Paper>
          ))}
          <Typography variant="h6" fontWeight={"bold"} paddingY={2}>Feedback Guidelines</Typography>
          {[
            "Define the intent of your feedback clearly in the title.",
            "Stay relevant: ensure your feedback aligns with specified title.",
            "Rest assured: your feedback is completely anonymous.",
            "Strive for constructive and actionable insights for your workplace, team, or organization.",
            "Avoid personal attacks, false accusations, or inappropriate content.",
            "For serious or legal concerns, consider official channels.",
            "Your feedback is valued; regular reviews ensure it is considered.",
            "Engage with the platform regularly to stay informed.",
            "Don't pass any derogatory remark on the basis of caste, creed, colour, gender, religion, sect or  other identity.Any statements that are derogatory or discriminatory will be subject for investigation and legal action.",
          ].map((item, index) => (
            <Typography variant="body2" key={index}>
              {index + 1}. {item}
            </Typography>
          ))}
        </Stack>
        <Dialog
          open={newFeedback.open}
          onClose={() =>
            setNewFeedback({ title: "", content: "", open: false })
          }
        >
          <DialogTitle>Submit Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Give anonymous feedback to your organization admin on any issue or
              suggestion
            </DialogContentText>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ marginY: 2 }}
              fullWidth
              value={newFeedback.title}
              onChange={(e) =>
                setNewFeedback({ ...newFeedback, title: e.target.value })
              }
            />
            <TextField
              id="outlined-basic"
              label="Content"
              multiline
              minRows={4}
              variant="outlined"
              sx={{ marginY: 1 }}
              fullWidth
              value={newFeedback.content}
              onChange={(e) =>
                setNewFeedback({ ...newFeedback, content: e.target.value })
              }
            />
            {error && <Alert severity="error">{error}</Alert>}
          </DialogContent>
          <DialogActions>
            <EasyButton2
              label="Cancel"
              width={100}
              onClick={() =>
                setNewFeedback({ title: "", content: "", open: false })
              }
            />
            <EasyButton2 label="Submit" width={100} onClick={submitFeedback} />
          </DialogActions>
        </Dialog>
        <Dialog
          open={newFeedback.isSubmitted}
          maxWidth="sm"
          onClose={() =>
            setNewFeedback({ title: "", content: "", isSubmitted: false })
          }
        >
          <DialogTitle>Feedback Submitted Successfully</DialogTitle>
          <DialogContent>
            <Box component="img" alt="Feedback" sx={{ width: "100%" }} src={FeedbackImg} />
            <DialogContentText>
              Your feedback has been received by your organization Admin. Thank
              you for your valuable feedback
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <EasyButton2
              label="OK"
              width={100}
              onClick={() =>
                setNewFeedback({ title: "", content: "", isSubmitted: false })
              }
            ></EasyButton2>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
