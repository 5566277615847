import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  MobileStepper,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import consultation from "../../../utils/consultation.jpeg";
import { useState } from "react";
import EasyButton2 from "../../../components/v2/easyButton2";
import { BaseURL } from "../../../utils/consts";
import axios from "axios";
import { Loader } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { panelPageMargin } from "../../../utils/consts";
import Panel from "../../../components/v2/panel";

const sections = [
  {
    title: "Demographics",
    subtitle: "Please answer the following questions about yourself",
    questions: [
      { question: "Nationality/ Country", type: "text" },
      { question: "Native Language", type: "text" },
      {
        question: "Do you believe in religion?",
        type: "radio",
        options: ["Yes", "No"],
      },
      { question: "If yes, which religion do you follow?", type: "text" },
      { question: "Are you spiritual?", type: "radio", options: ["Yes", "No"] },
    ],
  },
  {
    title: "Emotional Intensity Scale",
    subtitle:
      "In a scale of 1 to 10, how intensely you feel following emotions:",
    questions: [
      { question: "Emotional Intensity of Joy", type: "scale" },
      { question: "Emotional Intensity of Sadness", type: "scale" },
      { question: "Emotional Intensity of Anger", type: "scale" },
      { question: "Emotional Intensity of Fear", type: "scale" },
      { question: "Emotional Intensity of Trust", type: "scale" },
      { question: "Emotional Intensity of Disgust", type: "scale" },
      { question: "Emotional Intensity of Surprise", type: "scale" },
      { question: "Emotional Intensity of Anticipation", type: "scale" },
    ],
  },
  {
    title: "Emotional Awareness",
    subtitle: "Self awareness of emotions",
    questions: [
      {
        question: "How often do you recognize and understand your emotions?",
        type: "radio",
        options: ["Always", "Often", "Sometimes", "Rarely", "Never"],
      },
    ],
  },
  {
    title: "Coping Mechanisms",
    subtitle: "Dealing with intense emotions",
    questions: [
      {
        question:
          "Which strategies do you use to cope with your intense emotions?",
        type: "checkbox",
        options: [
          "Talking to someone",
          "Engaging in physical activity",
          "Listening to music",
          "Writing or journaling",
          "Practicing mindfulness or meditation",
          "Taking deep breaths",
          "Engaging in a hobby or creative activity",
          "Setting Boundaries",
          "Sleep",
          "Self care routine",
          "Organising",
          "Seeking Professional Help",
          "Other",
        ],
      },
    ],
  },
  {
    title: "Additional Comments",
    subtitle:
      "Please share any additional thoughts or insights that you would like to express to the consultant.",
    questions: [{ question: "Your thoughts", type: "multilineText" }],
  },
];

const AnswerInput = ({ question, type, hint, options, onChange }) => {
  switch (type) {
    case "text": {
      return (
        <TextField
          size="small"
          variant="outlined"
          placeholder={hint && "Eg: " + hint}
          sx={{ width: { xs: "100%", md: "60%" } }}
          onChange={(e) => {
            onChange(question, e.target.value);
          }}
        />
      );
    }
    case "radio": {
      return (
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            onChange(question, e.target.value);
          }}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
              />
            );
          })}
        </RadioGroup>
      );
    }
    case "scale": {
      return (
        <Slider
          aria-label="Always visible"
          //   defaultValue={5}
          step={1}
          min={0}
          max={10}
          valueLabelDisplay="on"
          sx={{
            width: { md: "50%", xs: 300 },
            marginLeft: "auto",
            paddingTop: { xs: 3, md: 5 },
            color: "#262262",
          }}
          onChange={(e) => {
            onChange(question, e.target.value);
          }}
        />
      );
    }
    case "checkbox": {
      return (
        // <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
        <Stack direction="row" spacing={5} flexWrap={"wrap"} gap={5}>
          {options.map((option, index) => {
            return (
              <FormControlLabel
                key={option}
                value={option}
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    sx={{ marginLeft: index === 0 ? "0px" : "-40px" }}
                    onChange={(e) =>
                      onChange(question, option, e.target.checked)
                    }
                  />
                }
                label={option}
              />
            );
          })}
        </Stack>
      );
    }
    case "multilineText": {
      return (
        <TextField
          multiline
          minRows={4}
          placeholder="Express your feelings here"
          sx={{ width: "100%" }}
          onChange={(e) => onChange(question, e.target.value)}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default function PsychologicalQuestions(props) {
  const [currentSection, setCurrentSection] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const submitQuiz = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${BaseURL}/api/client/addMeetingQuestions`,
        {
          answers,
          meetingID: props.meetingID,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      setOpen(true);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const handleInputChange = (question, value, isChecked = null) => {
    setAnswers((prev) => {
      const existingAnswerIndex = prev.findIndex(
        (item) => item.question === question
      );

      if (existingAnswerIndex !== -1) {
        // Update the existing answer object
        const updatedAnswers = [...prev];

        if (isChecked !== null) {
          // Handle checkboxes
          let answerArray = updatedAnswers[existingAnswerIndex].answer
            ? updatedAnswers[existingAnswerIndex].answer.split(", ")
            : [];
          if (isChecked) {
            answerArray.push(value);
          } else {
            answerArray = answerArray.filter((item) => item !== value);
          }
          updatedAnswers[existingAnswerIndex].answer = answerArray.join(", ");
        } else {
          // Handle other types like text, radio, and scale
          updatedAnswers[existingAnswerIndex].answer = value;
        }

        return updatedAnswers;
      } else {
        // Add a new answer object
        if (isChecked !== null) {
          // If checkbox
          return [...prev, { question: question, answer: value }];
        } else {
          // If other types
          return [...prev, { question: question, answer: value }];
        }
      }
    });
  };
  if (loading) return <Loader />;
  return (
    <Stack
      sx={{
        ...panelPageMargin,
        height: "100vh",
        paddingX: { md: 5, xs: 1 },
        paddingY: { md: 4, xs: 0 },
      }}
    >
      <Panel />
      <Typography variant="h6" fontWeight={700} paddingY={0}>
        Note: Please answer all the questions honestly to help the consultant
        understand you better before the meeting.
      </Typography>
      <Stack>
        <Typography
          fontSize={{ lg: 25, md: 25, sm: 22, xs: 22 }}
          fontWeight={700}
          paddingY={0}
        >
          Section {currentSection + 1}. {sections[currentSection].title}
        </Typography>
        <Typography fontSize={{ lg: 22, md: 22, sm: 20, xs: 20 }}>
          {sections[currentSection].subtitle}
        </Typography>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack spacing={3} paddingY={3}>
        {sections[currentSection].questions.map((question, index) => (
          <Stack
            key={question.question}
            spacing={5}
            direction={{
              md:
                currentSection === 2 ||
                currentSection === 3 ||
                currentSection === 4
                  ? "column"
                  : "row",
            }}
            justifyContent={"space-between"}
            alignItems={"start"}
          >
            <Typography variant="h6" gutterBottom>
              {index + 1}. {question.question}
            </Typography>
            <AnswerInput
              question={question.question}
              type={question.type}
              hint={question.hint}
              options={question.options}
              onChange={handleInputChange}
            />
          </Stack>
        ))}
      </Stack>
      {error && <Alert severity="error">{error}</Alert>}
      <div style={{ alignSelf: "flex-end" }}>
        <EasyButton2
          label={currentSection === sections.length - 1 ? "Finish" : "Next"}
          width={200}
          onClick={() =>
            currentSection === sections.length - 1
              ? submitQuiz()
              : setCurrentSection(currentSection + 1)
          }
        />
      </div>
      <MobileStepper
        variant="progress"
        steps={sections.length}
        position="static"
        activeStep={currentSection}
        sx={{ maxWidth: 400, flexGrow: 1, alignSelf: "center", width: "100%" }}
        nextButton={
          <Button
            size="small"
            onClick={() => setCurrentSection(currentSection + 1)}
            disabled={currentSection === sections.length - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => setCurrentSection(currentSection - 1)}
            disabled={currentSection === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <Dialog open={open}>
        <DialogTitle fontWeight={"bold"} textAlign={"center"}>
          Booking Questions Submitted Successful
        </DialogTitle>
        <DialogContent>
          <Box
            component="img"
            alt="Happy Life"
            src={consultation}
            sx={{
              width: "100%",
              display: "block",
              paddingBottom: 5,
            }}
          />

          <DialogContentText>
            We have successfully noted your answers. Your responses will help
            the consultant to guide you better through the mental wellness
            process .
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <EasyButton2
            label="Go To Home"
            width="200px"
            onClick={() => navigate(`/v2/client/home`)}
          />
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
