import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import LandingHeader from "./landingHeader";
import LandingFooter from "./landingFooter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const data = [
  {
    category: "General FAQs",
    questions: [
      {
        q: "What is the purpose of this mental health application?",
        a: "This application is designed to provide resources, tools, and guidance to individuals seeking information and support for their mental well-being.",
      },
      {
        q: "Is this application a substitute for professional medical advice?",
        a: "No, this app is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or another qualified health provider with any questions you may have regarding a medical condition.",
      },
      {
        q: "Is my data safe and confidential?",
        a: "Yes, we prioritize user privacy and ensure that your data is encrypted and stored securely. We do not share your personal information with third parties without your consent.",
      },
    ],
  },
  {
    category: "Usage FAQs",
    questions: [
      {
        q: "How do I create an account?",
        a: "To create an account, click on the 'Sign Up' button on the homepage and follow the instructions.",
      },
      {
        q: "I forgot my password. How can I reset it?",
        a: "Click on the 'Forgot Password?' link on the login page and follow the steps to reset your password.",
      },
      {
        q: "Can I track my mood and emotions with this app?",
        a: "Yes, our mood tracking feature allows users to log and monitor their emotions over time.",
      },
    ],
  },
  {
    category: "Features & Tools FAQs",
    questions: [
      {
        q: "What kind of resources does the app offer?",
        a: "The app provides a range of resources including articles, videos, self-help tools, and guided meditations tailored to mental well-being.",
      },
      {
        q: "Does the application have a crisis helpline or chatbot feature?",
        a: "Yes, in urgent situations, users can access a list of crisis helplines or chat with our AI-powered chatbot for immediate guidance. However, in emergencies, please call emergency services or seek professional help.",
      },
      {
        q: "Can I set reminders to practice mindfulness or take medications?",
        a: "Yes, our app has a reminder feature that can be customized for various activities and medications.",
      },
    ],
  },
  {
    category: "Subscription & Payments FAQs",
    questions: [
      {
        q: "Is this application free to use?",
        a: "While the app offers many free resources, some premium features require a subscription.",
      },
      {
        q: "How can I cancel my subscription?",
        a: "You can cancel your subscription by going to the 'Settings' section and following the cancellation instructions.",
      },
      {
        q: "Will my subscription auto-renew?",
        a: "Yes, subscriptions auto-renew unless canceled. You can manage your subscription settings in the 'Account' section.",
      },
    ],
  },
  {
    category: "Technical FAQs",
    questions: [
      {
        q: "Which devices and operating systems are compatible with this app?",
        a: "Our app is available for both Android and iOS devices. We regularly update it to ensure compatibility with the latest OS versions.",
      },
      {
        q: "I'm facing technical issues. How can I get help?",
        a: "Please visit our 'Support' section or contact our technical support team at support@email.com for assistance.",
      },
      {
        q: "How often is the content updated?",
        a: "We update our content library regularly, with new resources added every month.",
      },
    ],
  },
];
export default function Faqs() {
  return (
    <>
      <LandingHeader />{" "}
      <Stack
        paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
        paddingTop={15}
        spacing={5}
      >
        {data.map((item, index) => (
          <Stack key={index}>
            <Typography variant="h4" gutterBottom paddingBottom={2}>
              {item.category}
            </Typography>
            {item.questions.map((question) => (
              <Accordion key={question.q}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{question.q}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{question.a}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        ))}{" "}
      </Stack>
      <LandingFooter />
    </>
  );
}
