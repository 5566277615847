import {
  Stack,
  Typography,
  Paper,
  Alert,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EasyButton2 from "../../../components/v2/easyButton2";
import { BaseURL, helpLine } from "../../../utils/consts";
import axios from "axios";
import { Loader } from "../../../components/Loader";
import moment from "moment";
import { panelPageMargin } from "../../../utils/consts";
import Panel from "../../../components/v2/panel";
import Divider from "@mui/material/Divider";
export default function MeetingPaymentSuccess() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [meeting, setMeeting] = useState({});
  let { meetingID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let res = await axios.get(
          `${BaseURL}/api/client/meetingDetails/${meetingID}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setMeeting(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
        setError(err.response?.data?.message ?? err.message);
      }
    };
    fetchData();
  }, [meetingID]);
  if (loading) return <Loader />;
  return (
    <Stack
      sx={{ ...panelPageMargin }}
      minHeight={"100vh"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Panel />
      <Stack px={{ xs: 4, md: 0 }} py={0} alignItems={"center"}>
        <iframe
          title="lottie"
          style={{
            margin: 0,
            zIndex: 1,
            border: "none",
            height: 200,
            // position: "absolute",
            // top: "50%", // Move the top edge to the center of the container
            // left: "50%", // Move the left edge to the center of the container
            // transform: "translateY(-50%) translateX(-50%)",
          }}
          src="https://lottie.host/embed/40d36d17-e8e6-42eb-805e-efc94cdd1a59/JE3uuYoymR.json"
        ></iframe>
        <Typography
          variant="h4"
          mt={0}
          textAlign={"center"}
          fontWeight={"bold"}
          gutterBottom
        >
          Payment Successful!
        </Typography>
        <Typography
          variant="h6"
          textAlign={"center"}
          gutterBottom
          paddingBottom={2}
        >
          Thank you for your payment. Check the meeting details.
        </Typography>
        <Divider sx={{ width: "50%" }} />
        <Typography
          variant="h6"
          textAlign={"justify"}
          fontWeight={"bold"}
          paddingTop={2}
          paddingBottom={2}
          gutterBottom
        >
          Meeting Details
        </Typography>
        <Grid
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          container
        >
          {[
            {
              label: "Consultant",
              value: meeting?.specialist?.name,
            },
            {
              label: "Profession",
              value: meeting?.specialist?.profession,
            },
            {
              label: "Date",
              value: moment(meeting?.date).format("DD-MM-YYYY"),
            },
            {
              label: "Time",
              value: meeting?.slot?.split(" ")[0].toUpperCase(),
            },
            {
              label: "Session",
              value: meeting?.pack?.duration?.replace(/\s+\S+$/, ""),
            },
            {
              label: "Mode",
              value: "Online",
            },
          ].map((e, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={5}
              sx={{
                border: "1px solid grey",
                borderRadius: "2px",
                px: 2,
                py: 1,
                marginBottom: 2,
                marginX: 1,
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="" fontWeight={"bold"} gutterBottom>
                  {e.label}
                </Typography>
                <Typography variant="" gutterBottom>
                  {e.value}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
        {error && <Alert severity="error">{error}</Alert>}
        <EasyButton2
          label="Meeting Questions"
          width={"300px"}
          onClick={() =>
            navigate(
              `/v2/client/questions/${meetingID}/${meeting?.specialist?.profession}`
            )
          }
        />{" "}
        <Typography variant="" py={1} textAlign={"center"} gutterBottom>
          Please be on time for the meeting.
        </Typography>
      </Stack>
      <Paper
        sx={{
          padding: 5,
          backgroundColor: "#143F6B",
          color: "whitesmoke",
          alignSelf: "stretch",
          marginTop: 1,
          borderRadius: 0,
        }}
      >
        <Typography
          textAlign={"center"}
          fontSize={{
            lg: 24,
            md: 24,
            sm: 20,
            xs: 20,
          }}
        >
          In case of any queries, please reach out to us at <br />
          <a style={{ color: "orange" }} href={`tel:${helpLine}`}>
            {helpLine}
          </a>
        </Typography>
      </Paper>
    </Stack>
  );
}
