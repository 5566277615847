import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function ChatPrompts({ prompts, setNewQuestion, handleSubmit }) {
  const [samplePrompts, setSamplePrompts] = useState([]);

  function getRandomPrompts(prompts) {
    // Fisher-Yates (Durstenfeld) shuffle algorithm
    for (let i = prompts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [prompts[i], prompts[j]] = [prompts[j], prompts[i]]; // Swap elements
    }
    setSamplePrompts(prompts);
  }
  useEffect(() => {
    getRandomPrompts(prompts);
  }, [prompts]);
  return (
    <>
      {samplePrompts.map((e, index) => {
        return (
          <Stack
            key={index}
            onClick={() => {
              setNewQuestion(e.text);
              handleSubmit(e.text);
            }}
            sx={{
              minWidth: "220px",
              maxWidth: "320px",
              width: "100%",
              position: "relative",
              padding: { xs: 1, md: 3 },
              borderRadius: 3,
              marginY: 2,
              minHeight: { xs: "200px", md: "240px" },
              backgroundColor: "#f0f4f9",
              "&:hover": {
                backgroundColor: "whitesmoke",
              },
              cursor: "pointer",
              boxShadow: "none",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {e.emoji}
            </Typography>
            <Typography variant="body1" color={"gray"}>
              Eg: {e.text}
            </Typography>
            <ArrowForwardIcon
              sx={{ position: "absolute", bottom: 10, right: 10 }}
            />
          </Stack>
        );
      })}
    </>
  );
}
