import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { BaseURL, validateEmail, validatePhoneNo } from "../utils/consts";
import CareerImg from "../utils/career.png";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import EasyButton2 from "../components/v2/easyButton2";
import { useRef } from "react";
import { Loader } from "../components/Loader";
export default function Career() {
  const [user, setUser] = useState({ role: "" });
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const uploadCV = async (file) => {
    setError("");
    setFile(file);
  };

  const submitCV = async () => {
    try {
      setError("");
      if (!user.name) return setError("Please enter your name");
      if (!validatePhoneNo(user.phoneNo))
        return setError("Please enter valid 10 digit phone no.");
      if (!validateEmail(user.email))
        return setError("Please enter valid email");
      if (!user.address) return setError("Please enter your address");
      if (!user.role) return setError("Please select your role");
      if (!file) return setError("Please upload your CV");
      setLoading(true);
      let fetchS3URL = await axios.get(`${BaseURL}/api/landing/cvUrl`);
      const buffer = await file.arrayBuffer();
      // each entry of array should contain 8 bits
      const bytes = new Uint8Array(buffer);
      let uploadFileToS3 = await fetch(fetchS3URL.data, {
        method: "PUT",
        headers: {
          "Content-Type": "application/pdf",
        },
        body: bytes,
      });
      await axios.post(`${BaseURL}/api/landing/submitCareerApplication`, {
        ...user,
        cv: uploadFileToS3.url.split("?")[0],
      });
      setSubmitted(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response?.data?.message) setError(err.response.data.message);
      else setError(err.message);
    }
  };

  return (
    <Stack minHeight={"100vh"} padding={5} spacing={5}>
      <Stack
        direction={{ md: "row" }}
        justifyContent={"space-evenly"}
        alignItems={"start"}
        spacing={10}
        gap={5}
        paddingTop={6}
      >
        <Stack spacing={4} width={{ xs: "100%", md: "40%" }}>
          <Box
            component="img"
            alt="Happy Life"
            src={CareerImg}
            sx={{
              width: { xs: "100%", alignSelf: "center" },
              display: "block",
              borderRadius: 2,
            }}
          />
          <Typography variant="h6">
            Transform lives through technology. We're hiring passionate minds
            for our mental wellness mission. Engage with cutting-edge tech,
            collaborate with a seasoned, dynamic team, and experience rapid
            career progression enriched by continuous learning.
          </Typography>{" "}
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <Stack spacing={3}>
            <Stack>
              <Typography variant="h4" fontWeight={"bold"} gutterBottom>
                Careers at PeaceTwogether
              </Typography>
              <Typography variant="h6">
                Welcome to PeaceTwogether. Hope you make a lot of difference in
                people's lives.
              </Typography>
            </Stack>
            <TextField
              label="Enter name"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Enter phone no."
              value={user.phoneNo}
              onChange={(e) =>
                setUser({ ...user, phoneNo: e.target.value.trim() })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Enter email"
              value={user.email}
              onChange={(e) =>
                setUser({ ...user, email: e.target.value.trim() })
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Enter address"
              value={user.address}
              onChange={(e) => setUser({ ...user, address: e.target.value })}
              variant="outlined"
              margin="normal"
            />
            <TextField
              select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user.role}
              label="Roles"
              autoWidth
              margin="normal"
              onChange={(e) => setUser({ ...user, role: e.target.value })}
            >
              <MenuItem value={"Intership"}>Intership</MenuItem>
              <MenuItem value={"Volunteer"}>Volunteer</MenuItem>
              <MenuItem value={"Full-Time"}>Full-Time</MenuItem>
              <MenuItem value={"Freelancing"}>Freelancing</MenuItem>
            </TextField>
            <Button
              variant="outlined"
              sx={{
                color: "#662D91",
                borderColor: "#662D91",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#143F6B",
                  borderColor: "#143F6B",
                },
              }}
              onClick={() => fileInputRef.current.click()}
            >
              Upload CV (.pdf format)
            </Button>
            {file && (
              <Chip label={"Selected file: " + file.name} variant="outlined" />
            )}
            <input
              ref={fileInputRef}
              type="file"
              hidden
              accept=".pdf" // Only accept PDF files
              style={{ marginLeft: 10, hidden: true }}
              onChange={(e) => {
                uploadCV(e.target.files[0]);
              }}
            />
            {error !== "" && <Alert severity="error">{error}</Alert>}
            <EasyButton2 label="Submit" size="small" onClick={submitCV}>
              {" "}
            </EasyButton2>
          </Stack>
        )}
        <Dialog open={submitted}>
          <DialogTitle fontWeight={"bold"}>Application Submitted</DialogTitle>
          <DialogContent>
            <iframe
              title="Lottie"
              width={"100%"}
              height={"100%"}
              style={{ border: "none", marginBottom: 20 }}
              src="https://lottie.host/?file=452ff4e7-74b2-47db-b6e4-8b590c733fca/lEmqPWXGl4.json"
            ></iframe>
            <DialogContentText>
              Successfully noted your interest. We will get in touch with you
              soon. Thank you for wanting to make a positive impact for the
              masses.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <EasyButton2
              label={"Thanks"}
              width={150}
              onClick={() => navigate("/")}
            ></EasyButton2>
          </DialogActions>
        </Dialog>
      </Stack>
    </Stack>
  );
}
