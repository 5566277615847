import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { date: "Mon Feb 19 2024", score: 13 },
  { date: "Mon Feb 26 2024", score: 40 },
  { date: "Fri Mar 15 2024", score: 6 },
  { date: "Mon Mar 18 2024", score: 13 },
  { date: "Thu Mar 21 2024", score: 13 },
  { date: "Wed May 22 2024", score: 10 },
  { date: "Thu May 23 2024", score: 15 },
];

const scoreRanges = [
  { range: "0-13", label: "Low Stress", color: "#82ca9d" },
  { range: "14-26", label: "Medium Stress", color: "#ffc658" },
  { range: "27-40", label: "High Stress", color: "#ff0000" },
];

const CustomLegend = ({ ranges }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: 10,
      }}
    >
      {ranges.map((item, index) => (
        <div
          key={index}
          style={{ marginRight: 20, display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: item.color,
              marginRight: 5,
              borderRadius: "50%",
            }}
          />
          <span
            style={{ fontSize: 12 }}
          >{`${item.label} (${item.range})`}</span>
        </div>
      ))}
    </div>
  );
};

const PSSStressGraph = (props) => {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  const maxScore = Math.max(...(props.data || data).map((item) => item.score));

  const getGradientDefinition = () => {
    const gradientId = "stressGradient";
    const lowStressOffset = maxScore <= 13 ? 100 : (13 / maxScore) * 100;
    const mediumStressOffset = maxScore <= 26 ? 100 : (26 / maxScore) * 100;

    return (
      <linearGradient id={gradientId} x1="0" y1="1" x2="0" y2="0">
        <stop
          offset={`${lowStressOffset}%`}
          stopColor="#82ca9d"
          stopOpacity={0.8}
        />
        {maxScore > 13 && (
          <>
            <stop
              offset={`${lowStressOffset}%`}
              stopColor="#ffc658"
              stopOpacity={0.8}
            />
            <stop
              offset={`${mediumStressOffset}%`}
              stopColor="#ffc658"
              stopOpacity={0.8}
            />
          </>
        )}
        {maxScore > 26 && (
          <>
            <stop
              offset={`${mediumStressOffset}%`}
              stopColor="#ff0000"
              stopOpacity={0.8}
            />
            <stop offset="100%" stopColor="#ff0000" stopOpacity={0.8} />
          </>
        )}
      </linearGradient>
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: { md: "343px", xs: "500px" },
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        sx={{ width: "100%" }}
      >
        <b>Stress Test Results History</b>
        {media && <br />} (Perceived Stress Scale)
      </Typography>
      <ResponsiveContainer width="100%" height={"80%"}>
        <AreaChart
          data={props.data || data}
          margin={{ top: 10, right: 0, left: -40, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 10, fill: "gray" }} />
          <YAxis domain={[0, 40]} tick={{ fontSize: 10, fill: "gray" }} />
          <Tooltip />
          <defs>{getGradientDefinition()}</defs>
          <Area
            type="monotone"
            dataKey="score"
            stroke="#000"
            fill={`url(#stressGradient)`}
          />
        </AreaChart>
      </ResponsiveContainer>
      {true && <CustomLegend ranges={scoreRanges} />}
      <Typography sx={{}} pt={1} textAlign={"center"}>
        Score Records of all the Stress Assessments taken out of 40
      </Typography>
    </Stack>
  );
};

export default PSSStressGraph;
