import { Button } from "@mui/material";
export default function EasyButton2(props) {

  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: props.bgcolor || "#143F6B",
        "&:hover": {
          backgroundColor: "#662D91",
        },
        fontWeight: 750,
        borderRadius: 2,
        width: props.width || "100%",
        textTransform: props.textTransform,      
      }}
      onClick={props.onClick}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
    >
      {props.label}
    </Button>
  );
}
