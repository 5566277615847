import {
  Box,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Chip,
  Divider,
  Alert,
} from "@mui/material";
import Panel from "../../../components/v2/panel";
import { useState } from "react";
import axios from "axios";
import { BaseURL } from "../../../utils/consts";
import { useEffect } from "react";
import moment from "moment";
import Arrow from "../../../utils/Arrow.png";
import { Loader } from "../../../components/Loader";
export default function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(
        `${BaseURL}/api/client/subscriptions/clientSubscriptions`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setSubscriptions(res.data.subscriptions);
      setPackages(res.data.subscriptionPackages);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const subscribePackage = async (pack) => {
    try {
      setLoading(true);
      let res = await axios.post(
        `${BaseURL}/api/client/subscriptions/addSubscription`,
        {
          pack,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      window.location.href = res.data;
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      setError(e.response?.data?.message ?? e.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Panel />
      <Box component="main" sx={{ flexGrow: 1, padding: 3 }}>
        <Toolbar />
        {loading ? (
          <Loader />
        ) : (
          <Stack sx={{ margin: 2 }} spacing={1}>
            {error && <Alert severity="error">{error}</Alert>}
            <h1>Subscriptions</h1>
            {!localStorage.getItem("company") && (
              <>
                <Typography variant="h6" paddingBottom={5}>
                  Subscribe to a new package:
                </Typography>
                <Stack
                  direction={{ lg: "row" }}
                  flexWrap={"wrap"}
                  alignItems={{ lg: "start", xs: "center" }}
                  //paddingLeft={{ lg: 30 }}
                  paddingBottom={5}
                  justifyContent={"start"}
                  spacing={0}
                  gap={5}
                >
                  {packages?.map((pack, index) => {
                    return (
                      <Paper
                        key={index}
                        onClick={() => subscribePackage(pack)}
                        sx={{
                          width: 350,
                          cursor: "pointer",
                          padding: "30px",
                          backgroundColor: "#143F6B",
                          color: "white",
                          minWidth: "180px",
                          transition: "box-shadow 200ms ease-out 100ms",
                          "&:hover": {
                            backgroundColor: "#262262",
                            transform: "scale(1.05)",
                          },
                        }}
                      >
                        <Typography
                          variant="h7"
                          textAlign={"center"}
                          fontWeight={"bold"}
                          component="div"
                        >
                          {pack.name}
                        </Typography>
                        <br />
                        {pack.mrp && pack.mrp > pack.price ? (
                          <Typography
                            sx={{ fontSize: 48 }}
                            color="whitesmoke"
                            gutterBottom
                          >
                            <sup style={{ fontSize: 14 }}>Rs</sup> {pack.price}
                            <span
                              style={{
                                fontSize: 24,
                                textDecoration: "line-through",
                                paddingLeft: 5,
                              }}
                            >
                              {pack.mrp}
                            </span>
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ fontSize: 48 }}
                            color="whitesmoke"
                            gutterBottom
                          >
                            <sup style={{ fontSize: 14 }}>Rs</sup> {pack.price}
                          </Typography>
                        )}
                        <Divider color={"white"}></Divider>
                        <br />
                        <Typography fontSize={"14px"}>
                          Package for {pack.totalNoOfSessions} sessions of
                          <b> {pack.duration}</b> with a professional
                        </Typography>
                        <br />
                        <Typography fontWeight={"bold"}>
                          Call Features
                        </Typography>
                        <br />
                        {pack?.features?.map((feature, index) => {
                          return (
                            <Typography key={index} variant="body2">
                              <img src={Arrow} alt="" margin={5}></img>{" "}
                              {feature}
                            </Typography>
                          );
                        })}
                      </Paper>
                    );
                  })}
                </Stack>
              </>
            )}
            {subscriptions?.length !== 0 && (
              <Typography variant="h5" fontWeight={"bold"} paddingBottom={5}>
                All Your Subscribed Plans
              </Typography>
            )}
            <Stack
              direction={{ lg: "row" }}
              flexWrap={"wrap"}
              alignItems={{ lg: "start", xs: "center" }}
              justifyContent={"start"}
              gap={3}
            >
              {subscriptions?.map((pack, index) => {
                return (
                  <Paper
                    key={index}
                    style={{
                      width: 310,
                      padding: "30px",
                      marginLeft: 0,
                      backgroundColor:
                        pack.sessionsTaken === pack.totalNoOfSessions ||
                        moment(pack.expiryDate).isSameOrBefore(moment())
                          ? "gray"
                          : "#662d91",
                      color: "white",
                      transition: "box-shadow 200ms ease-out 100ms",
                      "&:hover": {
                        boxShadow:
                          pack.sessionsTaken === pack.totalNoOfSessions ||
                          moment(pack.expiryDate).isSameOrBefore(moment())
                            ? "gray"
                            : "1px 1px 10px 1px #1976d2",
                        backgroundColor:
                          pack.sessionsTaken === pack.totalNoOfSessions ||
                          moment(pack.expiryDate).isSameOrBefore(moment())
                            ? "gray"
                            : "#262262",
                      },
                    }}
                  >
                    {" "}
                    <Chip
                      sx={{
                        marginLeft: "160px",
                        backgroundColor: "white",
                      }}
                      label={
                        pack.sessionsTaken === pack.totalNoOfSessions ||
                        moment(pack.expiryDate).isSameOrBefore(moment())
                          ? "Expired"
                          : "Subscribed"
                      }
                      color="default"
                      variant="outlined"
                    />
                    <Typography
                      variant="h7"
                      fontWeight={"bold"}
                      component="div"
                    >
                      {pack.name}
                    </Typography>
                    <br />
                    <Typography
                      sx={{ fontSize: 48 }}
                      color="whitesmoke"
                      gutterBottom
                    >
                      <sup style={{ fontSize: 14 }}>Rs</sup> {pack.price}
                    </Typography>
                    <Divider color={"white"}></Divider>
                    <br />
                    <Typography fontSize={"14px"}>
                      Package for {pack.totalNoOfSessions} sessions of
                      <b> {pack.duration}</b> with a professional
                    </Typography>
                    <Typography fontSize={"14px"}>
                      Expiry Date:{" "}
                      {moment(pack.expiryDate).format("DD-MM-YYYY")}
                    </Typography>
                    <br />
                    <Typography fontWeight={"bold"}>Call Features</Typography>
                    <br />
                    {pack?.features?.map((feature, index) => {
                      return (
                        <Typography key={index} variant="body2">
                          <img src={Arrow} alt="" margin={5}></img> {feature}
                        </Typography>
                      );
                    })}
                    <Typography
                      variant="h7"
                      fontWeight={"bold"}
                      component="div"
                    >
                      Sessions Taken: {pack.sessionsTaken ?? 0}
                    </Typography>
                  </Paper>
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
