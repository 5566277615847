import { Stack, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


export default function StackedBarChart(props) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: {md:"343px",xs:"500px"},
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={2}
        sx={{ width: "100%" }}
      >
        <b>Monthly Breakup of MWS Score</b> (Activity Wise)
      </Typography>{" "}
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 10,
            right: 0,
            left:-30,
            bottom: 0,
          }}
          barSize={30} // Adjust the barSize value to make the bars thinner
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 10, color: "gray" }} />
          <YAxis
            label={{ value: "Score", angle: -90, position: "insideLeft" }}
            domain={[0, 100]}
            tick={{ fontSize: 10, color: "gray" }}
          />
          <Tooltip />
          <Legend  />
          {/* <Bar dataKey="AI Chat Analysis" stackId="a" fill="#8884d8" /> */}
          <Bar dataKey="CBT Quiz" stackId="a" fill="#82ca9d" />
          <Bar dataKey="Stress Tracker" stackId="a" fill="#ffc658" />
          {/* <Bar dataKey="DepressionTracker" stackId="a" fill="#6b5b95" /> */}
          <Bar dataKey="Depression Tracker" stackId="a" fill="#ff7f50" />
          <Bar dataKey="Consultation" stackId="a" fill="#a0d468" />
        </BarChart>
      </ResponsiveContainer>
      <Typography sx={{ paddingY: 1 }} textAlign={"center"}>
        The monthwise MWS score breakup of the user
      </Typography>
    </Stack>
  );
}
