import Stack from "@mui/material/Stack";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { Divider } from "@mui/material";
import Arrow from "../../../utils/Arrow.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Loader } from "../../../components/Loader";
import consultation from "../../../utils/consultation.jpeg";
import Panel from "../../../components/v2/panel";
import NewConsultantCard from "../../../components/v2/consultantCard";
import EasyButton2 from "../../../components/v2/easyButton2";
import { RazorpayCheckout } from "../../../utils/razorPay";
import CustomDatePicker from "../../../components/v2/datePicker";

export default function Booking() {
  let { consultantID } = useParams();
  const [packages, setPackages] = useState([]);
  const [meeting, setMeeting] = useState({
    about: "",
    problem: "",
    pack: "",
    slot: "",
  });
  const [consultant, setConsultant] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment());
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState({
    open: false,
    type: "domestic",
  });
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(
        `${BaseURL}/api/client/getDetailsToBook/${consultantID}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setConsultant(res.data.specialist);
      setSubscriptions(res.data.subscriptions);
      setPackages(res.data.meetingOptions);
      setMeeting({
        ...meeting,
        pack: res.data.meetingOptions[0],
      });
      fetchSlots(date);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const bookAppointment = async (type = "") => {
    try {
      if (new Date(date) <= new Date().setHours(0, 0, 0, 0))
        return setError("Cannot book past slots. Select a time in the future");
      setLoading(true);
      if (localStorage.getItem("company")) {
        let res = await axios.post(
          `${BaseURL}/api/client/subscriptions/bookMonthlyCorporateMeeting`,
          {
            ...meeting,
            date,
            specialistId: consultantID,
            companyID: localStorage.getItem("company"),
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setMeeting(res.data);
        setLoading(false);
        setOpen(true);
      } else if (meeting.pack.cost) {
        let res = await axios.post(
          `${BaseURL}/api/client/addMeeting`,
          { ...meeting, date, specialistId: consultantID, type },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        if (type === "international") window.location.href = res.data;
        else {
          RazorpayCheckout(res.data);
          setLoading(false);
        }
      } else {
        let res = await axios.post(
          `${BaseURL}/api/client/subscriptions/addMeetingToPackage`,
          { ...meeting, date, specialistId: consultantID },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        setMeeting(res.data);
        setLoading(false);
        setOpen(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.response?.data?.message ?? err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  const fetchSlots = async (newDate) => {
    try {
      let res = await axios.post(
        `${BaseURL}/api/client/getSlots`,
        { specialistId: consultantID, date: newDate },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      setSlots(res.data);
      if (res.data.length === 0)
        setError("Consultant unavailable on selected date");
      else {
        setError("");
        setMeeting({ ...meeting, slot: res.data[0].slot });
      }
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <Stack spacing={2} sx={{ ...panelPageMargin }}>
      <Panel />
      <Stack spacing={3} paddingX={5}>
        <Stack
          direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
          alignItems={{ md: "start", xs: "center" }}
          justifyContent={"space-around"}
          spacing={5}
        >
          <NewConsultantCard consultant={consultant} />

          <Stack
            spacing={{ xs: 4, md: 3 }}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <TextField
              id="meeting-type"
              name="meeting-type"
              label="Meeting Type"
              select
              variant="outlined"
              margin="normal"
              value={meeting.pack}
              sx={{
                width: "100%",
              }}
              onChange={(e) => setMeeting({ ...meeting, pack: e.target.value })}
            >
              {[...packages, ...subscriptions].map((pack, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={[...packages, ...subscriptions][index]}
                    sx={{ padding: 0 }}
                  >
                    <Typography component="div">
                      {pack.name} ({pack.duration.split(" ")[0]} mins)
                    </Typography>
                    {!pack.cost && (
                      <Chip
                        id="subscribed"
                        name="subscribed"
                        label="subscribed"
                        size="small"
                        sx={{ padding: 1 }}
                      ></Chip>
                    )}
                  </MenuItem>
                );
              })}
            </TextField>
            <CustomDatePicker
              date={date}
              minDate={moment()}
              maxDate={moment().add(1, "month")}
              onChange={(newValue) => {
                newValue?.toDate().setHours(0);
                newValue?.toDate().setMinutes(0);
                newValue?.toDate().setSeconds(0);
                setDate(newValue);
                fetchSlots(newValue);
              }}
            />
            <TextField
              id="meeting-slot"
              name="meeting-slot"
              label="Meeting Slot"
              select
              variant="outlined"
              margin="normal"
              sx={{
                width: "100%",
              }}
              value={meeting.slot}
              onChange={(e) => setMeeting({ ...meeting, slot: e.target.value })}
            >
              {slots.map((ele, index) => {
                if (ele.status === "available")
                  return (
                    <MenuItem key={index} value={slots[index].slot}>
                      {ele.slot}
                    </MenuItem>
                  );
                else return <div></div>;
              })}
            </TextField>
            <TextField
              id="problem"
              name="problem"
              label="Describe your problem"
              multiline
              fullWidth
              rows={4}
              value={meeting.problem}
              onChange={(e) =>
                setMeeting({ ...meeting, problem: e.target.value })
              }
            />
            {error && <Alert severity="error">{error}</Alert>}
            <div style={{ alignSelf: "end" }}>
              <Stack direction={"row"} spacing={5} alignItems={"center"}>
                <span>
                  <strong>Amount: ₹ </strong>
                  {meeting.pack?.cost ?? 0.0}
                </span>
                {loading && <CircularProgress color="secondary" />}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#143F6B",
                    "&:hover": {
                      backgroundColor: "#662D91",
                    },
                    fontWeight: 750,
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    if (meeting.pack === "") {
                      setError("Choose a package");
                      return;
                    }
                    if (meeting.problem === "") {
                      setError("Describe your problem");
                      return;
                    }
                    if (meeting.slot === "") {
                      setError("Choose a slot");
                      return;
                    }
                    setError("");
                    if (meeting.pack.cost)
                      setPaymentType({ open: true, type: "domestic" });
                    else bookAppointment();
                  }}
                >
                  Continue
                </Button>
              </Stack>
            </div>
          </Stack>
        </Stack>
        {!localStorage.getItem("company") && (
          <>
            <Typography
              textAlign={"center"}
              variant="h4"
              paddingY={5}
              fontWeight={"bold"}
              color={"#282c34"}
            >
              Mentorship Plans For Expert Advice
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "center" }}
              justifyContent={"center"}
              spacing={3}
            >
              {packages.map((pack, index) => {
                return (
                  <Paper
                    key={index}
                    sx={{
                      width: 295,
                      padding: "30px",
                      backgroundColor: "#143F6B",
                      borderRadius: 2,
                      color: "white",
                      minWidth: "180px",
                      transition: "box-shadow 200ms ease-out 100ms",
                      "&:hover": {
                        backgroundColor: "#262262",
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    <Typography
                      variant="h7"
                      textAlign={"center"}
                      fontWeight={"bold"}
                      component="div"
                    >
                      {pack.name}
                    </Typography>
                    <br />
                    {pack.cost && pack.cost !== pack.mrp ? (
                      <Typography
                        sx={{ fontSize: 48 }}
                        color="whitesmoke"
                        gutterBottom
                      >
                        <sup style={{ fontSize: 14 }}>Rs</sup> {pack.cost}
                        <span
                          style={{
                            fontSize: 24,
                            textDecoration: "line-through",
                            paddingLeft: 5,
                          }}
                        >
                          {pack.mrp}
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ fontSize: 48 }}
                        color="whitesmoke"
                        gutterBottom
                      >
                        <sup style={{ fontSize: 14 }}>Rs</sup> {pack.mrp}
                      </Typography>
                    )}

                    <Divider color={"white"}></Divider>
                    <br />
                    <Typography fontSize={"14px"}>
                      Introductory offer for <b>{pack.duration}</b> with a
                      professional
                    </Typography>
                    <br />
                    <Typography fontWeight={"bold"}>Call Features</Typography>
                    <br />
                    {pack.features.map((feature, index) => {
                      return (
                        <Typography key={index} variant="body2">
                          <img src={Arrow} alt="" margin={5}></img> {feature}
                        </Typography>
                      );
                    })}
                  </Paper>
                );
              })}
            </Stack>
          </>
        )}
        <Dialog open={open}>
          <DialogTitle fontWeight={"bold"} textAlign={"center"}>
            Booking Successful
          </DialogTitle>
          <DialogContent>
            <Box
              component="img"
              alt="Happy Life"
              src={consultation}
              sx={{
                width: "100%",
                display: "block",
                paddingBottom: 5,
              }}
            />

            <DialogContentText>
              Thank you for booking the appointment. You will receive a
              confirmation email shortly. Please be on time for the meeting.
              Have a nice day!
            </DialogContentText>
            <br />
            <strong>
              Please Note: We recommend you to answer the meeting questionnaire
              to make it easy for the consultant to guide you through your
              mental wellness process .
            </strong>
          </DialogContent>
          <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              sx={{ color: "grey" }}
              onClick={() => navigate("/v2/client/home")}
            >
              Skip
            </Button>

            <EasyButton2
              label="Meeting Questions"
              width="200px"
              onClick={() =>
                navigate(
                  `/v2/client/questions/${meeting._id}/${consultant?.profession}`
                )
              }
            />
          </DialogActions>
        </Dialog>
      </Stack>
      <Stack
        spacing={1.5}
        backgroundColor={"#143F6B"}
        color={"white"}
        p={5}
        mt={5}
      >
        <Typography variant="h5">Terms & Conditions</Typography>
        <Typography variant="h7">
          1. The client agrees to use the Easy Twogether Platform for
          audio-video meetings and to pay all applicable fees.
        </Typography>

        <Typography variant="h7">
          2. The client is responsible for the safety and security of their
          account information, including passwords, and for taking all necessary
          steps to ensure that their account is secure.
        </Typography>

        <Typography variant="h7">
          3. The client agrees to not use the Easy Twogether Platform for any
          illegal activities.
        </Typography>

        <Typography variant="h7">
          4. The client agrees that all video and audio recordings of meetings
          are the property of the Easy Twogether Platform and may be used for
          any purpose without the client’s permission.
        </Typography>

        <Typography variant="h7">
          5. The Easy Twogether Platform reserves the right to terminate the
          client’s account at any time and for any reason.
        </Typography>

        <Typography variant="h7">
          6. The client is responsible for any taxes, fees, or other costs
          associated with their use of the Easy Twogether Platform
        </Typography>

        <Typography variant="h7">
          7. The client agrees that the selected consultant for a paid
          consultation session can be replaced with equally or more qualified
          professional in case of some unavoidable circumstances like medical
          emergencies, connectivity issue or Act of God
        </Typography>

        <Typography variant="h7">
          8. The Easy Twogether Platform reserves the right to amend or change
          these terms and conditions at any time.
        </Typography>

        <Typography variant="h7">
          9. The client agrees to be bound by the terms and conditions of the
          Easy Twogether Platform.
        </Typography>
        <Typography variant="h7">
          10. The client consents the platform to share the insights of their
          counselling session (notes of consultant) to the future consultants
          booked on the platform by the same account. This ensures continuity of
          counselling provided and better support. If the client wants to deny
          the sharing of past consultant's record with a newly booked consultant
          then he/she can modify the consent in the review form provided right
          after the meeting ends
        </Typography>
      </Stack>
      <Dialog
        open={paymentType.open}
        onClose={() => setPaymentType({ open: false, type: "domestic" })}
      >
        <DialogTitle fontWeight={"bold"} textAlign={"center"}>
          Payment Method
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            value={paymentType.type}
            onChange={(e) =>
              setPaymentType({
                type: e.target.value,
                open: true,
              })
            }
          >
            <FormControlLabel
              value="domestic"
              control={<Radio />}
              label="Domestic/Indian"
            />
            <FormControlLabel
              value="international"
              control={<Radio />}
              label="International"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "grey", width: "100%" }}
            onClick={() => setPaymentType({ open: false, type: "domestic" })}
          >
            Cancel
          </Button>
          <EasyButton2
            label="Pay Now"
            width="200px"
            onClick={() => {
              bookAppointment(paymentType.type);
              setPaymentType({ open: false, type: "domestic" });
            }}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
