import { cloneElement } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Thumsup from "../../utils/thumbsup.png";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Paper } from "@mui/material";
import EasyButton2 from "../../components/v2/easyButton2";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import Logo from "../../components/logo";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function CorporateLanding() {
  const navigate = useNavigate();
  return (
    <>
      <ElevationScroll>
        <AppBar
          sx={{
            bgcolor: "white",
            paddingX: { xs: 2, md: 2, lg: 15, xl: 25 },
            paddingY: { lg: 1 },
          }}
        >
          <Toolbar>
            <Logo />
            <Button
              variant="contained"
              type="button"
              sx={{
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
                marginLeft: "auto",
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "#662D91", // adjust as needed for hover effect
                },
              }}
              onClick={() => navigate("/corporate/login")}
            >
              Sign In
            </Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Container>
        <Toolbar />
        <Box sx={{ my: 2, mx: { xs: 2, md: 2, lg: 15, xl: 5 } }}>
          <Stack
            direction={{ md: "row", xs: "column-reverse" }}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingTop={{ lg: 15, md: 15, sm: 5, xs: 5 }}
            spacing={5}
          >
            <Stack spacing={5} width={{ lg: "800px" }}>
              <Typography
                variant={"h4"}
                lineHeight={{ xs: "1.3" }}
                fontWeight={550}
              >
                The business transforming to support employees and families
              </Typography>
              <Typography>
                Elevate your business through transformative mental wellness
                support, nurturing well-being, and fostering team’s motivation.
                Enrich lives, strengthen bonds, and cultivate a harmonious
                work-family synergy that fuels lasting success and happiness.
              </Typography>
              <EasyButton2
                onClick={() => navigate("/corporate/signup")}
                label="Get Started"
                width="200px"
              />
            </Stack>
            <img
              src={Thumsup}
              alt="Corporate Thumbs Up"
              style={{
                width: "370px",
              }}
            />
          </Stack>
          <Typography
            variant={"h4"}
            textAlign={"center"}
            lineHeight={{ xs: "1.5" }}
            marginTop={20}
            color={"#050038"}
            fontWeight={700}
            marginBottom={5}
          >
            Support Your Valuable Employees without any Constraints
          </Typography>
          <Stack direction={{ lg: "row", md: "row" }} marginBottom={10}>
            {[
              {
                title: "Empowering Your Helper ",
                content:
                  "Help every person in your team be their best self. Give anonymous, unlimited and in-time mental health support to everyone who needs it, and save on costs.",
              },
              {
                title: "AI Powered Well-Being",
                content:
                  "Access 24/7 AI powered chat system for your team’s mental wellness and a personalized virtual assistant providing on-demand support and companionship in need",
              },
              {
                title: "Professional Guidance ",
                content:
                  "Access expert guidance for mental well-being in a personalized manner , fostering a professional approach to support your team’s holistic mental health.",
              },
            ].map((item, index) => (
              <Stack key={index} padding={5}>
                <Typography
                  fontWeight={700}
                  paddingBottom={3}
                  color={"#050038"}
                  variant="h5"
                >
                  {item.title}
                </Typography>
                <Typography color={"#050038"}>{item.content}</Typography>
              </Stack>
            ))}
          </Stack>
          <Typography
            variant={"h4"}
            textAlign={"center"}
            lineHeight={{ xs: "1.5" }}
            marginTop={20}
            color={"#050038"}
            fontWeight={700}
            marginBottom={5}
          >
            Comprehensive Mental Well-being Solutions for the Modern Workplace
          </Typography>{" "}
          <Stack
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            gap={2}
            paddingY={5}
          >
            {[
              {
                icon: (
                  <DownloadDoneIcon fontSize="large" sx={{ color: "white" }} />
                ),
                title: "Mental Well-being Score (MWS)",
                content:
                  "A scientifically designed system for monthly tracking, ensuring you're always updated on the mental health status of your employees",
              },
              {
                title: "Boost Productivity by 4x",
                content:
                  "Ensure the happiness of your employees to significantly amplify their productivity.",
              },
              {
                title: "Regular Professional Sessions",
                content:
                  "12 annual sessions with seasoned professionals for consistent monitoring.",
              },
              {
                title: "Admin Dashboard",
                content:
                  "A comprehensive view of your employees' progress and MWS, sorted department-wise.",
              },
              {
                title: "Intervention Capabilities",
                content:
                  "Proactive support for team members who might be struggling.",
              },
              {
                title: "Anonymous Feedback Mechanism",
                content:
                  "Cultivate trust and open communication with an anonymous feedback system.",
              },
              {
                title: "24x7 AI Chat System - EasyBuddy",
                content:
                  "Round-the-clock support ensuring your employees are never alone.",
              },
              {
                title: "Private Journaling Feature",
                content:
                  "A completely private space for self-reflection and healing through writing.",
              },
              {
                title: "Choose Your Professional",
                content:
                  "Tailored support by allowing employees to choose professionals based on their unique challenges.",
              },
              {
                title: "Overall Productivity Improvements",
                content:
                  "Beyond individual productivity, witness better collaboration and innovation from a mentally healthy team.",
              },
              {
                title: "Cost Savings",
                content:
                  "Benefit from enhanced productivity and reduced attrition rates.",
              },
              {
                title: "Competitive Pricing",
                content:
                  "We offer unparalleled value compared to the potential costs of mental health issues.",
              },
              {
                title: "Enhanced Brand Image",
                content:
                  "Position your company as a progressive, employee-centric organization.",
              },
              {
                title: "Reduced Sick Days",
                content:
                  "Fewer disruptions with mentally healthy employees taking fewer sick days.",
              },
              {
                title: "Customizable Solutions",
                content:
                  "Tailored to fit the unique needs of your organization.",
              },
              {
                title: "Data-Driven Decisions",
                content:
                  "Real-time data for HR and leadership teams to make informed choices.",
              },
              {
                title: "Scalable Solutions",
                content:
                  "Whether you're a startup or an enterprise, our solutions scale to meet your needs, ensuring all employees benefit.",
              },
              {
                title: "Ongoing Support and Resources",
                content:
                  "Continuous updates and resources to keep up with the ever-evolving landscape of mental well-being.",
              },
              // {
              //   title: "Training for Leadership",
              //   content:
              //     "Equip your leadership with the tools to understand, empathize, and manage teams better, ensuring mental well-being is a top-down approach.",
              // },
            ].map((item, index) => (
              <Paper
                key={index}
                sx={{ padding: 5, height: 350, width: 330, borderRadius: 3 }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  backgroundColor={"#143F6B"}
                  padding={2}
                  height={"50px"}
                  width={"50px"}
                  borderRadius={2}
                >
                  {item.icon ?? (
                    <DownloadDoneIcon
                      fontSize="large"
                      sx={{ color: "white" }}
                    />
                  )}
                </Box>
                <Typography
                  variant="h5"
                  fontWeight={550}
                  paddingBottom={4}
                  paddingTop={7}
                >
                  {item.title}
                </Typography>
                <Typography variant="body2" color={"text.secondary"}>
                  {item.content}
                </Typography>
              </Paper>
            ))}
          </Stack>
          <div
            style={{ textAlign: "center", marginTop: 100, paddingBottom: 50 }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => navigate("/corporate/signup")}
              sx={{
                backgroundColor: "black",
                width: "200px",
                "&:hover": {
                  backgroundColor: "#662D91",
                },
              }}
            >
              Get Started
            </Button>
          </div>
        </Box>
      </Container>
      <Stack
        direction={{ md: "row" }}
        alignItems={{
          lg: "flex-end",
          md: "flex-end",
          sm: "center",
          xs: "center",
        }}
        sx={{
          justifyContent: "space-between",
          marginBottom: 5,
          paddingX: { xs: 5, md: 10, lg: 15, xl: 25 },
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "start" }}
          height={"100px"}
          spacing={2}
        >
          <Logo />
          <Typography color={"rgba(0, 0, 0, 0.57)"}>
            ©Copyright 2023 | Virtue Mind Pvt. Ltd
          </Typography>
        </Stack>

        <Button
          variant="text"
          color="inherit"
          type="button"
          onClick={() => navigate("/corporate/t&c")}
          sx={{ color: "rgba(0, 0, 0, 0.57)", textTransform: "none" }}
        >
          Terms & Conditions
        </Button>
      </Stack>
    </>
  );
}
