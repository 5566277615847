import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Panel from "../../../components/v2/panel";
import { Paper } from "@mui/material";
import RightPanel from "../../../components/v2/rightPanel";
import { Alert, Avatar, Button, Rating, Stack } from "@mui/material";
import EasyBuddy from "../../../utils/easyBuddy.png";
import Journal from "../../../utils/Character.png";
import Quiz from "../../../utils/quiz.png";
import axios from "axios";
import { BaseURL, headingFontSize } from "../../../utils/consts";
import { useNavigate } from "react-router-dom";
import MeetingCard2 from "../../../components/v2/meetingCard2";
import moment from "moment";
import Carousel from "../../../components/v2/carousel";
import Legal from "../../../utils/legal.png";
import PersonalityTest from "../../../utils/personality_test.jpg";
import Stress from "../../../utils/stress.jpg";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EasyButton2 from "../../../components/v2/easyButton2";
import NoRecordsImg from "../../../utils/noRecords.jpg";
export default function Home() {
  const [mws, setMws] = useState(0);
  const [user, setUser] = useState({});
  const [futureMeetings, setFutureMeetings] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [history, setHistory] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      let res = await axios.get(
        `${BaseURL}/api/client/dashboard/clientDetails`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setUser(res.data.user);
      setMws(res.data.mws);
      localStorage.setItem(
        "pic",
        JSON.stringify({ profilePic: res.data.user.photoLink })
      );
      localStorage.setItem("username", res.data.user.username ?? "Anonymous");
      localStorage.setItem("company", res.data.user?.corporate?.company ?? "");
      setHistory(res.data.history);
      setFutureMeetings(res.data.upcomingMeetings);
      setFeedbacks(res.data.feedbacks);
      setReviews(res.data.reviews);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Panel isHome={true} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: { lg: 5, md: 5, sm: 5, xs: 5 },
          paddingLeft: { lg: 2, md: 2, sm: 2, xs: 2 },
          paddingRight: { lg: 2, xs: 2 },
          width: "100%",
        }}
      >
        <Toolbar />
        <Stack
          spacing={5}
          paddingX={{ xs: 1, sm: 4 }}
          width={"100%"}
          direction={{ md: "row" }}
          justifyContent={{ md: "space-around" }}
        >
          <Stack
            //  width={"100%"}
            spacing={2}
          >
            {error && <Alert severity="error">{error}</Alert>}
            <Stack spacing={2} width={"100%"} alignContent={"end"}>
              <Carousel user={user} mws={mws} />

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#143F6B",
                  width: "180px",
                  alignSelf: { lg: "end", xs: "center" },
                  textTransform: "none",
                  fontWeight: 750,
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#662D91",
                  },
                }}
                onClick={() => navigate("/v2/client/services")}
              >
                Book a Session
              </Button>
            </Stack>
            <Typography
              color={"#143F6B"}
              gutterBottom
              id="services"
              fontWeight={750}
              fontSize={headingFontSize}
              pl={{ lg: 1 }}
              textAlign={{ sm: "start", xs: "start" }}
              paddingTop={2}
            >
              Discover
            </Typography>
            <Stack
              flexDirection={{ sm: "row" }}
              flexWrap={"wrap"}
              gap={5}
              alignItems={"center"}
              //spacing={1}
              justifyContent={"space-around"}
              paddingTop={2}
              paddingBottom={5}
            >
              {[
                {
                  title: "Easy Buddy",
                  subtitle: "Chat with your AI Assistant",
                  img: EasyBuddy,
                  color: "#143F6B",
                  path: "/v2/client/easyBuddy",
                },
                {
                  title: "CBT Quiz",
                  subtitle: "Understand yourself",
                  img: Quiz,
                  color: "#824AAD",
                  path: "/v2/client/cbtQuiz",
                },
                {
                  title: "Mood Journal",
                  subtitle: "Write your Journals",
                  img: Journal,
                  color: "#8884d8",
                  path: "/v2/client/moodJournal",
                },
                {
                  title: "Stress Tracker",
                  subtitle: "Track your stress",
                  img: Stress,
                  color: "#ffffff",
                  fontColor: "#143F6B",
                  path: "/v2/client/stressTracker",
                },
                {
                  title: "Depression Tracker",
                  subtitle: "Track your depression",
                  img: PersonalityTest,
                  color: "#e8e4d9",
                  fontColor: "#143F6B",
                  path: "/v2/client/depressionTracker",
                },
                {
                  title: "Legal Buddy",
                  subtitle: "Legal Support",
                  img: Legal,
                  color: "#5179A3",
                  path: "/v2/client/legalBuddy",
                },
              ].map((item, index) => (
                <Paper
                  key={index}
                  onClick={() => navigate(`${item.path}`)}
                  style={{
                    backgroundColor: item.color,
                    color: item.fontColor ?? "white",
                    padding: 15,
                    paddingTop: 25,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minWidth: "260px",
                    //width: "100%",
                    height: "310px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginTop: 0,
                  }}
                >
                  <Typography variant="h5" fontWeight={550}>
                    {item.title}
                  </Typography>
                  <Typography marginBottom={"40px"}>{item.subtitle}</Typography>
                  <img
                    src={item.img}
                    alt="Easy Buddy"
                    height={item.title === "Daily Quiz" ? 140 : 180}
                  />
                </Paper>
              ))}
            </Stack>
            <AppointmentRecords
              futureMeetings={futureMeetings}
              history={history}
              feedbacks={feedbacks}
              reviews={reviews}
              navigate={navigate}
            />
          </Stack>
          <RightPanel />
        </Stack>
      </Box>
    </Box>
  );
}

function AppointmentRecords({
  feedbacks,
  reviews,
  navigate,
  history,
  futureMeetings,
}) {
  const [value, setValue] = useState("My Appointments");
  const tabs = [
    "My Appointments",
    "History",
    "Consultant Feedbacks",
    "My Reviews",
  ];
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            onChange={(e, newValue) => setValue(newValue)}
            aria-label="lab API tabs example"
          >
            {tabs.map((e, index) => {
              return <Tab key={index} label={e} value={tabs[index]} />;
            })}
          </TabList>
        </Box>
        <TabPanel value={value} index={value} sx={{ minHeight: "100vh" }}>
          {value === "My Appointments" ? (
            <MyAppointments
              futureMeetings={futureMeetings}
              navigate={navigate}
            />
          ) : value === "History" ? (
            <MyHistory history={history} navigate={navigate} />
          ) : value === "Consultant Feedbacks" ? (
            <ConsultantFeedback feedbacks={feedbacks} navigate={navigate} />
          ) : (
            <MyReviews reviews={reviews} navigate={navigate} />
          )}
        </TabPanel>
      </TabContext>
    </>
  );
}

function MyReviews({ reviews, navigate }) {
  return (
    <>
      <Typography
        color={"#143F6B"}
        gutterBottom
        id="reviews"
        fontWeight={750}
        fontSize={headingFontSize}
        pl={{ lg: 1 }}
        textAlign={{ sm: "start", xs: "center" }}
      >
        Your Reviews
      </Typography>
      <Stack
        direction={{ lg: "row" }}
        sx={{
          width: "100%", // take up full width of its container
          maxWidth: "1000px", // max width can be adjusted based on design needs
          minWidth: "250px",
          justifyContent: "start",
        }}
        gap={5}
        alignItems={"center"}
        paddingTop={2}
        flexWrap={"wrap"}
        paddingBottom={5}
      >
        {[...reviews].map((item, index) => (
          <Paper
            key={index}
            style={{ width: "100%", padding: 20, borderRadius: 10 }}
          >
            <Stack direction={"row"} alignItems={"start"} spacing={2}>
              <Avatar
                src={item.specialist?.photoLink}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/v2/client/consultantProfile/${item.specialist._id}`
                  );
                }}
              />
              <Stack>
                <Typography>
                  <strong>Meeting Date :</strong>{" "}
                  {moment(item.date).format("DD/MM/YYYY")}
                </Typography>
                <Typography>
                  <strong> Consultant: </strong>
                  {item.specialist.name}
                </Typography>
                <Typography>
                  <strong>Your Review: </strong>
                  {item.clientReview?.message}
                </Typography>
                <Rating
                  readOnly
                  precision={0.5}
                  value={item.clientReview?.rating}
                />
              </Stack>
            </Stack>
          </Paper>
        ))}
        {reviews.length === 0 && (
          <NoRecord label={"Review"} navigate={navigate} />
        )}
      </Stack>
    </>
  );
}

function MyAppointments({ futureMeetings, navigate }) {
  return (
    <>
      <Typography
        color={"#143F6B"}
        gutterBottom
        id="appointments"
        fontWeight={750}
        fontSize={headingFontSize}
        pl={{ lg: 1 }}
        textAlign={{ sm: "start", xs: "center" }}
      >
        My Appointments
      </Typography>
      <Stack
        direction={{ lg: "row" }}
        sx={{
          width: "100%", // take up full width of its container
          maxWidth: "1000px", // max width can be adjusted based on design needs
          minWidth: "250px",
          justifyContent: "start",
        }}
        gap={5}
        alignItems={"center"}
        paddingTop={2}
        flexWrap={"wrap"}
        paddingBottom={5}
      >
        {[...futureMeetings].map((item, index) => (
          <MeetingCard2 key={index} data={item} />
        ))}
        {futureMeetings.length === 0 && (
          <NoRecord label={"Upcoming Meeting"} navigate={navigate} />
        )}
      </Stack>
    </>
  );
}

function ConsultantFeedback({ feedbacks, navigate }) {
  return (
    <>
      <Typography
        color={"#143F6B"}
        gutterBottom
        id="feedbacks"
        fontWeight={750}
        fontSize={headingFontSize}
        pl={{ lg: 1 }}
        textAlign={{ sm: "start", xs: "center" }}
      >
        Consultant Feedbacks
      </Typography>
      <Stack
        direction={{ lg: "row" }}
        sx={{
          width: "100%", // take up full width of its container
          maxWidth: "1000px", // max width can be adjusted based on design needs
          minWidth: "250px",
          justifyContent: "start",
        }}
        gap={5}
        alignItems={"center"}
        paddingTop={2}
        flexWrap={"wrap"}
        paddingBottom={5}
      >
        {[...feedbacks].map((item, index) => (
          <Paper
            key={index}
            style={{ width: "100%", padding: 20, borderRadius: 10 }}
          >
            <Stack direction={"row"} alignItems={"start"} spacing={2}>
              <Avatar
                src={item.specialist?.photoLink}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/v2/client/consultantProfile/${item.specialist._id}`
                  );
                }}
              />
              <Stack>
                <Typography>
                  <strong>Meeting Date :</strong>{" "}
                  {moment(item.date).format("DD/MM/YYYY")}
                </Typography>
                <Typography>
                  <strong> Consultant: </strong>
                  {item.specialist.name}
                </Typography>
                <Typography>
                  <strong>Problem: </strong>
                  {item.problem}
                </Typography>
                <Typography>
                  <strong>Feedback: </strong>
                  {item.consultantFeedback.message}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        ))}
        {feedbacks.length === 0 && (
          <NoRecord label={"Consultant Feedback"} navigate={navigate} />
        )}
      </Stack>
    </>
  );
}

function MyHistory({ history, navigate }) {
  return (
    <>
      <Typography
        color={"#143F6B"}
        id="history"
        fontWeight={750}
        fontSize={headingFontSize}
        pl={{ lg: 1 }}
        textAlign={{ sm: "start", xs: "center" }}
      >
        Booking History
      </Typography>
      <Stack
        direction={{ lg: "row" }}
        sx={{
          width: "100%", // take up full width of its container
          maxWidth: "1000px", // max width can be adjusted based on design needs
          minWidth: "250px",
        }}
        gap={5}
        alignItems={"center"}
        justifyContent={"flex-start"}
        paddingTop={2}
        flexWrap={"wrap"}
        paddingBottom={5}
      >
        {[...history].map((item, index) => (
          <MeetingCard2
            isHistory={true}
            key={index}
            data={item}
            disabled={true}
          />
        ))}
        {history.length === 0 && (
          <NoRecord label={"Past Sessions"} navigate={navigate} />
        )}
      </Stack>
    </>
  );
}

function NoRecord({ label, navigate }) {
  return (
    <Stack alignItems={"center"} width={"100%"}>
      <Box component={"img"} src={NoRecordsImg} sx={{ width: "250px" }} />
      <Typography color={"#143F6B"} textAlign={"center"} mt={2}>
        No {label} Found
      </Typography>
      <Typography
        variant="h6"
        fontWeight={"bold"}
        color={"#143F6B"}
        textAlign={"center"}
        gutterBottom
      >
        Book a new session now with a professional of your choice
      </Typography>
      <EasyButton2
        label="Book a new session"
        width={"250px"}
        onClick={() => {
          navigate("/v2/client/services");
        }}
      />
    </Stack>
  );
}
