import { useState, useCallback } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import box from "../../utils/box.png";
import { Avatar, IconButton, Paper, Stack, useMediaQuery } from "@mui/material";

import HomeIcon from "../../utils/sidepanel/Home.png";
import HelpIcon from "../../utils/sidepanel/Help.png";
import { useNavigate } from "react-router-dom";
import FeedbackIcon from "@mui/icons-material/Feedback";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import PollIcon from "@mui/icons-material/Poll";
import InventoryIcon from "@mui/icons-material/Inventory";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SchoolIcon from "@mui/icons-material/School";
import Image from "../../utils/profile.jpg";
import { safeJSONParse } from "../../utils/consts";
import Logo from "../logo";

const drawerWidth = 240;

export default function ConsultantPanel(props) {
  const [mobileOpen, setMobileOpen] = useState(window.innerWidth > 1000);
  const [selectedTab, setSelectedTab] = useState("Home");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:999px)");

  const tabSelect = (anchor) => {
    if (anchor === "logout") {
      localStorage.setItem("consultantToken", "");
      navigate("/");
      return;
    }
    setSelectedTab(anchor);
    if (!props.isDashboard) navigate(`/consultant/dashboard`);

    if (props.isDashboard && window.innerWidth > 1000) {
      document.getElementById(anchor)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      return;
    }
    setMobileOpen(false);
    setTimeout(() => {
      document.getElementById(anchor)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 1000);
  };
  function MenuItem(index, tab) {
    return (
      <ListItem
        key={index}
        disablePadding
        onClick={() => (tab.link ? navigate(tab.link) : tabSelect(tab.anchor))}
      >
        <ListItemButton>
          <ListItemIcon>
            <IconButton>
              {tab.image ? (
                <Paper sx={{ padding: 0.7, display: "flex" }}>
                  <img
                    color="blue"
                    height={"15px"}
                    width={"15px"}
                    src={tab.image}
                    alt="Icon"
                  />{" "}
                </Paper>
              ) : (
                <Paper sx={{ padding: 0.4, display: "flex" }}>
                  {tab.icon}{" "}
                </Paper>
              )}
            </IconButton>{" "}
          </ListItemIcon>
          <ListItemText primary={tab.label} />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          elevation: 0,
          boxShadow: "none",
        }}
      >
        <Stack>
          {props.alert && (
            <Stack
              sx={{
                width: "100%",
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={700} paddingY={0.5}>
                {props.alert}
              </Typography>
            </Stack>
          )}
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Logo />
            {isSmallScreen ? (
              <IconButton
                sx={{ margin: 0 }}
                onClick={() => setMobileOpen(!mobileOpen)}
              >
                <img
                  src={box}
                  alt=""
                  style={{ cursor: "pointer" }}
                  width={"30px"}
                />
              </IconButton>
            ) : (
              <Stack direction={"row"} spacing={2}>
                <Stack color={"#143F6B"} paddingX={2}>
                  <Typography fontWeight={700} variant="body1">
                    {localStorage.getItem("consultantName") ?? "Consultant"}
                  </Typography>
                  <Typography fontWeight={500} fontSize={10}>
                    Consultant Profile
                  </Typography>
                </Stack>
                <Avatar
                  src={
                    safeJSONParse(localStorage.getItem("consultantProfile"))
                      ?.profilePic ?? Image
                  }
                  alt={localStorage.getItem("consultantName") ?? "A"}
                  sx={{ width: 46, height: 46, bgcolor: "#262262" }}
                >
                  {localStorage.getItem("consultantName")?.charAt(0) ?? "A"}
                </Avatar>
              </Stack>
            )}
          </Toolbar>
        </Stack>
      </AppBar>
      <Drawer
        open={mobileOpen}
        variant={isSmallScreen ? "temporary" : "permanent"}
        onClose={useCallback(() => {
          setMobileOpen(false);
        }, [])}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            height: {
              xs: "calc(100% - 63.63px)",
              md: "calc(100% - 83px)",
            },
            marginTop: { md: 13, xs: 8 }, // Shifting the visual part of the drawer down
            width: drawerWidth,
            paddingTop: 2,
            borderTopRightRadius: 20,
            boxSizing: "border-box",
            backgroundColor: "#143F6B",
            color: "#fff",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "& *": {
              scrollbarWidth: "thin",
            },
          },
        }}
      >
        <Stack justifyContent={"space-between"} sx={{ height: "100%" }}>
          <Stack>
            <List>
              {[
                {
                  label: "Home",
                  image: HomeIcon,
                  anchor: "home",
                },
                {
                  label: "Schedule",
                  icon: (
                    <PollIcon
                      sx={{
                        color: "#143F6B",
                        height: "20px",
                        width: "20px",
                        padding: 0,
                        margin: 0,
                      }}
                    />
                  ),
                  anchor: "schedule",
                },
                {
                  label: "Today",
                  icon: (
                    <DomainVerificationIcon
                      sx={{ color: "#143F6B", height: "20px", width: "20px" }}
                    />
                  ),
                  anchor: "today",
                },
                {
                  label: "Upcoming",
                  icon: (
                    <FeedbackIcon
                      sx={{ color: "#143F6B", height: "20px", width: "20px" }}
                    />
                  ),
                  anchor: "future",
                },
                {
                  label: "History",
                  icon: (
                    <InventoryIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  anchor: "history",
                },
                {
                  label: "My Profile",
                  icon: (
                    <AccountBoxIcon
                      sx={{ height: "20px", width: "20px", color: "#143F6B" }}
                    />
                  ),
                  link: "/consultant/profile",
                },
              ].map((tab, index) => MenuItem(index, tab))}
            </List>

            <Typography paddingX={2} fontWeight={700} variant="body2">
              Support
            </Typography>
            <List>
              {[
                {
                  label: "Reviews",
                  image: HelpIcon,
                  anchor: "reviews",
                },
                {
                  label: "Knowledge Hub",
                  icon: (
                    <SchoolIcon
                      sx={{ color: "#143F6B", height: "20px", width: "20px" }}
                    />
                  ),
                  link: "/consultant/knowledge",
                },
                {
                  label: "Logout",
                  icon: (
                    <PowerSettingsNewIcon
                      sx={{ color: "#143F6B", height: "20px", width: "20px" }}
                    />
                  ),
                  anchor: "logout",
                },
              ].map((tab, index) => MenuItem(index, tab))}
            </List>
          </Stack>
          <Stack paddingY={5} width={"100%"} alignItems={"center"}>
            <Typography textAlign={"center"} fontSize={10}>
              {" "}
              ©Copyright 2024 | Virtue Mind Pvt. Ltd
            </Typography>
            <a
              href="https://neuronxresearch.com/"
              textAlign={"center"}
              fontSize={10}
              target="_blank"
              underline="hover"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "10px",
                transition: "color 0.3s ease", // optional for smooth transition
              }}
              onMouseEnter={(e) => (e.target.style.color = "#29E259")} // change color on hover
              onMouseLeave={(e) => (e.target.style.color = "white")} // revert color
            >
              Powered by NEURONX RESEARCH
            </a>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
