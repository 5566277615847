import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../utils/consts";
import EasyButton2 from "../v2/easyButton2";
export default function DeleteJournal({
  deleteJournal,
  setDelete,
  error,
  setError,
  setJournals = null,
}) {
  const navigate = useNavigate();
  const deleteJournalFn = async () => {
    try {
      setError("");
      await axios.delete(
        `${BaseURL}/api/client/journals/deleteJournal/${deleteJournal.journalID}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (setJournals) {
        setJournals((prev) =>
          prev.filter((j) => j._id !== deleteJournal.journalID)
        );
        setDelete({ dialog: false, journalID: "" });
      } else navigate("/v2/client/moodJournal");
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  return (
    <>
      <Dialog open={deleteJournal.dialog}>
        <DialogTitle>Delete Journal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the journal?
          </DialogContentText>
          {error && (
            <Alert sx={{ marginY: 2 }} severity="error">
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            width={100}
            label="Yes"
            onClick={deleteJournalFn}
          ></EasyButton2>
          <EasyButton2
            width={100}
            label="No"
            onClick={() => setDelete({ dialog: false, journalID: "" })}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </>
  );
}
