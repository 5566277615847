import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import Profile from "../../utils/profile.jpg";
import axios from "axios";
import { BaseURL } from "../../utils/consts";
import { useNavigate } from "react-router-dom";
export default function SearchBar() {
  const [consultants, setConsultants] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      if (window.innerWidth < 1000) return;
      let res = await axios.get(`${BaseURL}/api/client/dashboard/searchBar`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setConsultants(res.data.specialists);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Autocomplete
        options={consultants}
        autoHighlight
        onChange={(e, value) => {
          if (value?._id) navigate(`/v2/client/consultantProfile/${value._id}`);
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              style={{ width: "50px", height: "50px", borderRadius: 5 }}
              src={option.photoLink ?? Profile}
              alt=""
            />
            {option.name}{" "}
            <div
              style={{
                backgroundColor: "#D7D7D7",
                width: 8,
                height: 8,
                borderRadius: "50%",
                margin: "10px",
              }}
            ></div>
            {option.profession}{" "}
            <div
              style={{
                backgroundColor: "#D7D7D7",
                width: 8,
                height: 8,
                borderRadius: "50%",
                margin: "10px",
              }}
            ></div>
            {option.specialisation}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search  Therapist , Peer Group Members and more"
            sx={{
              width: "40vw",
              input: {
                color: "black",
                border: "none", // Remove the border
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
