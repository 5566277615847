import { Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EasyButton from "../../../components/easyButton";

export default function SubscriptionPaymentSuccess() {
  const { clientID, subscriptionID } = useParams();
  const navigate = useNavigate();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      spacing={5}
    >
      <Typography
        fontWeight={700}
        fontSize={{ md: "2rem", xs: "1.5rem" }}
        textAlign={"center"}
      >
        {" "}
        Subscription Payment Successful
      </Typography>
      <EasyButton
        label="Go To Home"
        size="small"
        width="300px"
        onClick={() => navigate("/v2/client/home")}
      />
    </Stack>
  );
}
