import {
  Alert,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  MenuItem,
  Menu,
  Box,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "../../../components/v2/panel";
import EasyButton2 from "../../../components/v2/easyButton2";
import MoodChart from "../../../components/v2/moodChart";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import DeleteJournal from "../../../components/v2/deleteJournal";
import { emotionColors } from "../../../utils/consts";
import NoRecordsImg from "../../../utils/noRecords.jpg";

const moods = [
  {
    emoji: "😡",
    color: "#FFB6A6",
    emotion: "Angry",
  },
  {
    emoji: "🤨",
    color: "#FFCF55",
    emotion: "Confused",
  },
  {
    emoji: "😕",
    color: "#FFF731",
    emotion: "Sad",
  },
  {
    emoji: "🙂",
    color: "#00FFFF",
    emotion: "Neutral",
  },
  {
    emoji: "😊",
    color: "#29E259",
    emotion: "Happy",
  },
];

export default function MoodJournal() {
  const [journals, setJournals] = useState([]);
  const [deleteJournal, setDelete] = useState({ dialog: false, journalID: "" });
  const [newJournal, setNewJournal] = useState({ open: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [currentJournal, setCurrentJournal] = useState(null);
  const [emotionStats, setEmotionStats] = useState([]);

  const handleMenuOpen = (event, journal) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentJournal(journal); // Track the currently selected journal
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentJournal(null);
  };
  const fetchData = async () => {
    try {
      let res = await axios.get(`${BaseURL}/api/client/journals/journals`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setJournals(res.data.journals);
      setEmotionStats(res.data.emotions);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const addJournal = async (emotion) => {
    try {
      let res = await axios.post(
        `${BaseURL}/api/client/journals/addJournal`,
        { title: "", content: "", emotion },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setJournals([res.data, ...journals]);
      navigate(`/v2/client/journal/${res.data._id}`);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Stack sx={{ ...panelPageMargin }}>
        <Panel />
        {error && (
          <Alert sx={{ marginTop: 5 }} severity="error">
            {error}
          </Alert>
        )}
        {AskFeeling(addJournal)}
        <MoodChart data={emotionStats} />
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingX={{ xs: 2, md: 10 }}
          paddingBottom={{ xs: 2, md: 5 }}
        >
          <Typography variant="h5" fontWeight={"bold"} color={"#143F6B"}>
            Recently Added
          </Typography>
          <EasyButton2
            width="200px"
            label="Add New Journal"
            onClick={() => setNewJournal({ open: true })}
          ></EasyButton2>
        </Stack>

        {journals.length === 0 && <NoRecord setNewJournal={setNewJournal} />}
        <Grid
          container
          spacing={2}
          gap={{ xs: 5, lg: 8 }}
          mb={5}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          {journals.map((j, i) => {
            return (
              <Paper
                sx={{
                  padding: 3,
                  marginY: 1,
                  borderRadius: 3,
                  width: { xs: "300px", md: "500px" },
                  height: "200px",
                  cursor: "pointer",
                  backgroundColor: emotionColors[j.emotion] + "80",
                }}
                key={i}
                onClick={() => navigate(`/v2/client/journal/${j._id}`)}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    backgroundColor={"#662D91"}
                    width={"fit-content"}
                    borderRadius={1}
                    color={"white"}
                  >
                    <IconButton sx={{ zIndex: 10 }} color="inherit">
                      <AccessTimeIcon sx={{ fontSize: 12 }} />
                    </IconButton>{" "}
                    <Typography fontSize={12} pr={1}>
                      {moment(j.timeStamp).format("DD MMM YY")}
                    </Typography>
                  </Stack>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMenuOpen(event, j)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Stack>
                <Typography fontWeight={"bold"}>{j.title}</Typography>
                {j.content.substring(0, 60) +
                  (j.content.length > 60 ? "..." : "")}
              </Paper>
            );
          })}
          {currentJournal && (
            <JournalMenu
              journal={currentJournal}
              anchorEl={anchorEl}
              onClose={handleMenuClose}
            />
          )}
        </Grid>
      </Stack>
      <DeleteJournal
        deleteJournal={deleteJournal}
        setDelete={setDelete}
        error={error}
        setError={setError}
        setJournals={setJournals}
      />
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: 3,
          },
        }}
        open={newJournal.open}
        onClose={() => setNewJournal({ open: false })}
      >
        {AskFeeling(addJournal)}
      </Dialog>
    </>
  );
  function NoRecord({ setNewJournal }) {
    return (
      <Stack alignItems={"center"} width={"100%"} mb={10}>
        <Box component={"img"} src={NoRecordsImg} sx={{ width: "250px" }} />
        <Typography color={"#143F6B"} textAlign={"center"} mt={2}>
          No Journals Found
        </Typography>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          color={"#143F6B"}
          textAlign={"center"}
          gutterBottom
          px={10}
        >
          Writing a mood journal can help you track your emotions, identify
          patterns and gain insights into your mental health. It can also help
          you develop a more positive mindset, practice gratitude and improve
          your overall well-being.
        </Typography>
        <EasyButton2
          label="Write a new journal"
          width={"250px"}
          onClick={() => setNewJournal({ open: true })}
        />
      </Stack>
    );
  }

  function JournalMenu({ journal, anchorEl, onClose }) {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        TransitionComponent={Fade}
        transitionDuration={100}
        sx={{
          boxShadow: "none",
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/v2/client/journal/${journal._id}`);
            onClose();
          }}
        >
          <EditIcon sx={{ mr: 1, fontSize: 18 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClose();
          }}
        >
          <ShareIcon sx={{ mr: 1, fontSize: 18 }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDelete({
              dialog: true,
              journalID: journal._id,
            });
            onClose();
          }}
        >
          <DeleteIcon sx={{ mr: 1, fontSize: 18 }} />
          Delete
        </MenuItem>
      </Menu>
    );
  }
}
function AskFeeling(addJournal) {
  return (
    <Stack
      sx={{
        padding: { xs: 3, md: 5 },
        borderBottom: "1px solid #143F6B",
      }}
    >
      <Typography variant="h6" color={"#143F6B"}>
        Hello, {localStorage.getItem("username")}
      </Typography>
      <Typography variant="h5" fontWeight={"bold"} color={"#143F6B"} mb={2}>
        How are you feeling today?
      </Typography>
      <Stack direction={"row"} flexWrap={"wrap"} gap={2}>
        {moods.map((j, i) => (
          <Paper
            key={i}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 2,
              marginY: 1,
              cursor: "pointer",
              borderRadius: 3,
              backgroundColor: j.color,
              "&:hover": {
                backgroundColor: j.color + "83",
              },
            }}
            onClick={() => addJournal(j.emotion)}
          >
            <Typography textAlign={"center"} variant="h4" color={"#143F6B"}>
              {j.emoji}
            </Typography>
            <Typography
              textAlign={"center"}
              variant="h6"
              color={"#143F6B"}
              ml={2}
            >
              {j.emotion}
            </Typography>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
}
