import { Stack, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
const CustomLegend = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        flexWrap: "wrap",
        marginRight: "20px",
        gap: "2px",
      }}
    >
      {props.data?.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
            width: "200px",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: COLORS[index],
              marginRight: "5px",
              boxShadow: "0px 0px 5px 0px rgba(1,0,0,0.1)",
            }}
          ></div>
          <span style={{ color: "black", paddingLeft: "1px", flexGrow: 1 }}>
            {props.data && props.data[index].name}
          </span>
          <span style={{ margin: "auto" }}>
            {props.data && props.data[index].value}
          </span>
        </div>
      ))}
    </div>
  );
};

const COLORS = [
  "#f6eef2",
  "#687495",
  "#20B2AA",
  "#f79bbc",
  "#233a5a",
  "#2196F3",
];

const ConsultantPieChart = (props) => {
  const CenteredLabel = ({ cx, cy }) => {
    return (
      <>
        <text
          x={cx}
          y={cy - 10}
          style={{ fontSize: "25px" }}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {props.consultants}
        </text>
        <text
          x={cx}
          y={cy + 10}
          style={{ fontSize: "12px", fill: "gray" }}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {props.label || "Consultants"}{" "}
        </text>
      </>
    );
  };
  return (
    <Stack
      sx={{
        width: "100%",
        height: { md: "343px", xs: "500px" },
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography
        variant=""
        width={"100%"}
        textAlign={"center"}
        pb={{md:2}}
        sx={{ width: "100%" }}
      >
        <b> {props.title} </b>
      </Typography>{" "}
      <Stack
        alignItems={"center"}
        justifyContent={"space-around"}
        direction={{ md: "row" }}
        height={"100%"}
      >
        <PieChart
          width={220}
          height={220}
        >
          <Pie
            data={props.data}
            cx={110}
            cy={110}
            labelLine={false}
            label={<CenteredLabel />}
            innerRadius={60}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
          >
            {[{}, {}, {}, {}, {}, {}].map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <CustomLegend colors={COLORS} data={props.data} />
      </Stack>
      {props.description && (
        <Typography sx={{ paddingY: 1 }} textAlign={"center"}>
          {props.description}
        </Typography>
      )}
    </Stack>
  );
};

export default ConsultantPieChart;
