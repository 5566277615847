import { useState,useEffect } from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Alert,
  Avatar,
  IconButton,
  ListItemAvatar,
  Stack,
} from "@mui/material";
import Profile from "../../utils/profile.jpg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { BaseURL } from "../../utils/consts";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function RightPanel() {
  const [favorites, setFavorites] = useState([]);
  const [all, setAll] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const markFavorite = async (event, _id, isFavorite) => {
    try {
      event.stopPropagation();
      setError("");
      if (isFavorite) {
        //update state before api update to unmark favourite
        setFavorites((prev) => prev.filter((tab) => tab._id !== _id));
      } else {
        //update state before api update to mark favourite
        setFavorites((prev) => [...prev, all.find((tab) => tab._id === _id)]);
      }
      await axios.put(
        `${BaseURL}/api/client/dashboard/toggleFavorite/${_id}`,
        {},
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
      //on error reverse the state of the favorite
      if (isFavorite) {
        setFavorites((prev) => [...prev, all.find((tab) => tab._id === _id)]);
      } else {
        setFavorites(favorites.filter((tab) => tab._id !== _id));
      }
    }
  };
  const fetchData = async () => {
    try {
      setError("");
      let res = await axios.get(`${BaseURL}/api/client/dashboard/rightPanel`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setAll(res.data.specialists);
      setFavorites(res.data.favorites);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Stack
      // width={{lg:"80%"}}
      minWidth={{ lg: 320 }}
      marginX={0}
    >
      {error && (
        <Alert sx={{ marginY: 2 }} severity="error">
          {error}
        </Alert>
      )}
      <Typography
        color={"#143F6B"}
        fontWeight={750}
        paddingBottom={2}
        paddingLeft={{ lg: 2 }}
        variant="h6"
      >
        Your Favorites
      </Typography>
      <List
        sx={{
          maxWidth: 380,
          minWidth: 250,
          width: "100%",
          bgcolor: "background.paper",
          color: "#143F6B",
        }}
      >
        {[...favorites].map((tab, index) => (
          <ListItem
            key={index}
            sx={{ cursor: "pointer", marginY: 2 }}
            onClick={() => {
              navigate("/v2/client/consultantProfile/" + tab._id);
            }}
            alignItems="center"
            secondaryAction={
              <IconButton
                sx={{}}
                edge="end"
                onClick={(e) => {
                  markFavorite(e, tab._id, true);
                }}
              >
                <FavoriteIcon
                  sx={{
                    height: "25px",
                    width: "25px",
                    color: "#f65b4e",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar
                alt={tab.name}
                src={tab.photoLink ?? Profile}
                sx={{ height: "64px", width: "64px" }}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ marginLeft: "20px" }}
              primary={<Typography variant="body1">{tab.name}</Typography>}
              secondary={tab.profession}
            />
          </ListItem>
        ))}
      </List>
      <Typography
        color={"#143F6B"}
        paddingY={2}
        paddingLeft={{ lg: 2 }}
        fontWeight={750}
        variant="h6"
      >
        Wellness Consultants
      </Typography>
      <List
        sx={{
          maxWidth: 380,
          minWidth: 250,
          width: "100%",
          bgcolor: "background.paper",
          color: "#143F6B",
          height: 300,
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        {[...all].map((tab, index) => (
          <ListItem
            key={index}
            sx={{ cursor: "pointer", marginY: 0 }}
            alignItems="center"
            onClick={() => {
              navigate("/v2/client/consultantProfile/" + tab._id);
            }}
            secondaryAction={
              <IconButton
                sx={{}}
                edge="end"
                onClick={(e) => {
                  markFavorite(
                    e,
                    tab._id,
                    favorites.some(
                      (item) => item._id.toString() === tab._id.toString()
                    )
                  );
                }}
              >
                <FavoriteIcon
                  sx={{
                    height: "25px",
                    width: "25px",
                    color: favorites.some(
                      (item) => item._id.toString() === tab._id.toString()
                    )
                      ? "#5179A3"
                      : "gray",
                  }}
                />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <img
                alt=""
                src={tab.photoLink ?? Profile}
                height={"64px"}
                width={"64px"}
                style={{ borderRadius: 5 }}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ marginLeft: "20px" }}
              primary={<Typography variant="body1">{tab.name}</Typography>}
              secondary={tab.profession}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
