import { Stack } from "@mui/material";
import EasyButton2 from "../components/v2/easyButton2";
import { useEffect } from "react";
import axios from "axios";
import { BaseURL } from "../utils/consts";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function UnsubscribeMail() {
  const [reSubscribed, setResubscribed] = useState(false);
  const { token } = useParams();
  const unsubscribe = async () => {
    try {
      await axios.post(`${BaseURL}/api/landing/unsubscribe`, { token: token });
    } catch (e) {
      console.error("Error:", e.message);
    }
  };
  const resubscribe = async () => {
    try {
      await axios.post(`${BaseURL}/api/landing/resubscribe`, { token: token });
      setResubscribed(true);
    } catch (e) {
      console.error("Error:", e.message);
    }
  };
  useEffect(() => {
    unsubscribe();
  }, []);
  return (
    <Stack
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      p={5}
      spacing={5}
    >
      {reSubscribed ? (
        <h2>
          Your mail id has been successfully re-subscribed for all future
          PeaceTwogether email communications
        </h2>
      ) : (
        <>
          <h2>
            Your mail id has been successfully un-subscribed from all future
            PeaceTwogether email communications
          </h2>
          <EasyButton2 label="Re-subscribe" width={200} onClick={resubscribe} />
        </>
      )}
    </Stack>
  );
}
