import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";
import { Avatar, Stack } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import HourglassFullIcon from "@mui/icons-material/HourglassEmpty";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EasyButton2 from "./easyButton2";

export default function MeetingCard2(props) {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        borderRadius: 10,
        boxShadow: "1px 1px 2px 1px rgba(5,0,56,0.15)",
      }}
    >
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center", md: "flex-start" }}
      >
        <Stack spacing={0.2} alignItems={{ md: "center" }}>
          <Avatar
            src={props.data?.specialist?.photoLink}
            variant="square"
            onClick={() =>
              navigate(
                `/v2/client/consultantProfile/${props.data?.specialist?._id}`
              )
            }
            sx={{
              height: "200px",
              width: "180px",
              alignSelf: { md: "center" },
              cursor: "pointer",
            }}
          />
          <Typography
            color={"#0B4D76"}
            variant="body1"
            textAlign={"center"}
            fontWeight={550}
          >
            {props.data?.specialist?.name}
          </Typography>
          <Typography color={"#0B4D76"} textAlign={"center"} variant="body2">
            {props.data?.specialist?.profession}
          </Typography>
        </Stack>
        <Stack
          marginX={3}
          spacing={1}
          height={"100%"}
          width={"220px"}
          justifyContent={"space-between"}
          paddingTop={"20px"}
          paddingBottom={"10px"}
        >
          <strong
            style={{
              textAlign: "center",
              paddingTop:
                props.isHistory && props.data.clientReview?.message
                  ? "15px"
                  : "0px",
            }}
          >
            Meeting Details
          </strong>
          <Stack direction="row" spacing={2}>
            <CheckBoxOutlinedIcon
              sx={{
                color: "rgba(5, 0, 56, 0.7)",
                height: "20px",
                width: "20px",
              }}
            />{" "}
            <Typography variant="body1">{props.data?.pack?.name}</Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <HourglassFullIcon
              sx={{
                color: "rgba(5, 0, 56, 0.7)",
                height: "20px",
                width: "20px",
              }}
            />
            <Typography variant="body1">
              {props.data?.pack?.duration.split(" ")[0]} Minute Session
            </Typography>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <CalendarMonthIcon
              sx={{
                color: "rgba(5, 0, 56, 0.7)",
                height: "20px",
                width: "20px",
              }}
            />
            <Typography variant="body1">
              {moment(props.data?.date).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <AccessAlarmsIcon
              sx={{
                color: "rgba(5, 0, 56, 0.7)",
                height: "20px",
                width: "20px",
              }}
            />
            <Typography variant="body1">
              {props.data?.slot.split("-")[0].toUpperCase()}
            </Typography>
          </Stack>
          {props.isHistory && !props.data.clientReview?.message && (
            <EasyButton2
              label={"Write a Review"}
              onClick={() => {
                navigate(`/v2/client/addReview/${props.data._id}`);
              }}
            />
          )}
          {!props.isHistory && props.data.questions?.length === 0 && (
            <EasyButton2
              label={"Meeting Questions"}
              width={"100%"}
              onClick={() => {
                navigate(
                  `/v2/client/questions/${props.data._id}/${props.data?.specialist?.profession}`
                );
              }}
            />
          )}
          {!props.isHistory && (
            <EasyButton2
              disabled={props.disabled}
              label={"Join Meeting"}
              width={"100%"}
              onClick={() => {
                navigate(
                  `/v2/client/videoCall/${props.data._id}/${
                    props.data.client.username ?? "Anonymous"
                  }`
                );
              }}
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
