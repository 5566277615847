import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { date: "Day 1", score: 36 },
  { date: "Day 2", score: 5 },
  { date: "Day 3", score: 20 },
  { date: "Day 4", score: 55 },
  { date: "Day 5", score: 20 },
  { date: "Day 6", score: 10 },
  { date: "Day 7", score: 20 },
];

const ranges = [
  { range: "0-9", label: "Minimal Symptoms", color: "#82ca9d" },
  { range: "10-18", label: "Mild Symptoms", color: "#ffc658" },
  { range: "19-27", label: "Moderate Symptoms", color: "#ffa500" },
  { range: "28-40", label: "Severe Symptoms", color: "#ff0000" },
];

const CustomLegend = ({ ranges }) => {
  return (
    <Stack
      sx={{
        flexWrap: "wrap",
        justifyContent: { xs: "start", md: "center" },
        alignItems: { xs: "start", md: "center" },
        m: 1,
        flexDirection: { md: "row" },
      }}
    >
      {ranges.map((item, index) => (
        <div
          key={index}
          style={{ marginRight: 20, display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: item.color,
              marginRight: 5,
              borderRadius: "50%",
            }}
          />
          <span
            style={{ fontSize: 12 }}
          >{`${item.label} (${item.range})`}</span>
        </div>
      ))}
    </Stack>
  );
};

const CBTGraph = (props) => {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  const maxScore = Math.max(...(props.data || data).map((item) => item.score));

  const getGradientDefinition = () => {
    const gradientId = "depressionGradient";
    const lowDepressionOffset = maxScore <= 13 ? 100 : (13 / maxScore) * 100;
    const mediumDepressionOffset = maxScore <= 19 ? 100 : (19 / maxScore) * 100;
    const highDepressionOffset = maxScore <= 28 ? 100 : (28 / maxScore) * 100;

    return (
      <linearGradient id={gradientId} x1="0" y1="1" x2="0" y2="0">
        <stop
          offset={`${lowDepressionOffset}%`}
          stopColor="#82ca9d"
          stopOpacity={0.8}
        />
        {maxScore > 13 && (
          <>
            <stop
              offset={`${lowDepressionOffset}%`}
              stopColor="#ffc658"
              stopOpacity={0.8}
            />
            <stop
              offset={`${mediumDepressionOffset}%`}
              stopColor="#ffc658"
              stopOpacity={0.8}
            />
          </>
        )}
        {maxScore > 19 && (
          <>
            <stop
              offset={`${mediumDepressionOffset}%`}
              stopColor="#ffa500"
              stopOpacity={0.8}
            />
            <stop
              offset={`${highDepressionOffset}%`}
              stopColor="#ffa500"
              stopOpacity={0.8}
            />
          </>
        )}
        {maxScore > 28 && (
          <>
            <stop
              offset={`${highDepressionOffset}%`}
              stopColor="#ff0000"
              stopOpacity={0.8}
            />
            <stop offset="100%" stopColor="#ff0000" stopOpacity={0.8} />
          </>
        )}
      </linearGradient>
    );
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: { md: "343px", xs: "500px" },
        backgroundColor: "white",
        borderRadius: "10px",
        border: "1px solid lightgray",
        p: 2,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 15px;",
      }}
    >
      <Typography textAlign={"center"} sx={{}}>
        <b>CBT Assessment Results</b>
        {media ? <br /> : null}
        {" "}(CBT Evaluation)
      </Typography>
      <ResponsiveContainer width="100%" height={"70%"}>
        <AreaChart
          data={props.data || data}
          margin={{ top: 10, right: 0, left: -40, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tick={{ fontSize: 10, fill: "gray" }} />
          <YAxis domain={[0, 40]} tick={{ fontSize: 10, fill: "gray" }} />
          <Tooltip />
          <defs>{getGradientDefinition()}</defs>
          <Area
            type="monotone"
            dataKey="score"
            stroke="#000"
            fill={`url(#depressionGradient)`}
          />
        </AreaChart>
      </ResponsiveContainer>
      {ranges && <CustomLegend ranges={ranges} />}
      <Typography
        sx={{ ml: { md: 0, xs: 1 }, fontSize: { md: 12, xs: 14 } }}
        textAlign={{ md: "center", xs: "start" }}
      >
        Score Records of all the CBT Assessments taken out of 40.
      </Typography>
    </Stack>
  );
};

export default CBTGraph;
