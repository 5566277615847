import { Typography, Stack } from "@mui/material";
import LandingHeader from "./v2/landing/landingHeader";
import LandingFooter from "./v2/landing/landingFooter";

export default function PolicyPage() {
  return (
    <Stack
      paddingTop={{ lg: 15, md: 15, sm: 10, xs: 10 }}
      paddingX={{ lg: 25, md: 5, sm: 5, xs: 5 }}
      paddingBottom={5}
    >
      <LandingHeader />
      <Stack id="terms">
        <Typography variant="h4" gutterBottom>
          Terms & Conditions
        </Typography>
        <b>1. The Terms and Conditions</b>
        The following are the Terms and Conditions (the "Agreement") which
        govern your access and use of our online platform through which therapy
        may be provided (collectively the "Platform"). This website is owned and
        operated by Virtue Mind Private Limited located at 17 S block A New
        Alipore, Kolkata 700053 (support@peacetwogether.com). The Platform may
        be provided or be accessible via multiple websites or applications
        whether owned and/or operated by us or by third parties, including,
        without limitation, the website peacetwogether.com is an entity of
        Virtue Mind Private limited. By accessing or using the Platform, you are
        entering into this Agreement. You should read this Agreement carefully
        before starting to use the Platform. If you do not agree to be bound to
        any term of this Agreement, you must not access the Platform. When the
        terms "we", "us", "our" or similar are used in this Agreement, they
        refer to any company that owns and operates the Platform (the
        "Company").
        <br />
        <br />
        <b>2. The Therapists and Therapist Services</b>
        The Platform may be used to connect you with a Therapist who will
        provide services to you through the Platform ("Therapist Services"). For
        all Therapists The Therapists are independent providers who are neither
        our employees nor agents nor representatives. The Platform's role is
        limited to enabling the Therapist Services. The Therapists themselves
        are responsible for the performance of the Therapy Services. If you feel
        the Therapist Services provided by the Therapist do not fit your needs
        or expectations, you may change to a different Therapist who provides
        services through the Platform. While we have a large database of
        Therapists, you should note that because of licensure requirements that
        differ from jurisdiction to jurisdiction, not all Therapists available
        on our database will be available for you to match with, or at any
        particular time, or for any set period of time. If a Therapist you have
        been connected with stops using the Platform at any time after you have
        been connected, we will send an email to notify you that your Therapist
        is no longer on the Platform and that you have the opportunity to match
        with a new Therapist. While we hope the Therapist Services are
        beneficial to you, you understand, agree and acknowledge that they may
        not be the appropriate solution for everyone's needs and that they may
        not be appropriate for every particular situation and/or may not be a
        complete substitute for a face-to-face examination and/or care in every
        particular situation. Emergency Service IF YOU ARE THINKING ABOUT
        SUICIDE OR IF YOU ARE CONSIDERING HARMING YOURSELF OR OTHERS OR IF YOU
        FEEL THAT ANY OTHER PERSON MAY BE IN ANY DANGER OR IF YOU HAVE ANY
        MEDICAL EMERGENCY, YOU MUST IMMEDIATELY CALL YOUR LOCAL EMERGENCY
        SERVICES NUMBER AND NOTIFY THE RELEVANT. THE PLATFORM IS NOT DESIGNED
        FOR USE IN ANY OF THE AFOREMENTIONED CASES AND THE THERAPISTS CANNOT
        PROVIDE THE ASSISTANCE REQUIRED IN ANY OF THE AFOREMENTIONED CASES. THE
        PLATFORM IS NOT INTENDED FOR THE PROVISION OF CLINICAL DIAGNOSIS
        REQUIRING AN IN-PERSON EVALUATION AND YOU SHOULD NOT USE IT IF YOU NEED
        ANY OFFICIAL DOCUMENTATION OR APPROVALS FOR PURPOSES SUCH AS, BUT NOT
        LIMITED TO, COURT-ORDERED THERAPY OR EMOTIONAL SERVICE DOG
        CERTIFICATION. IT IS ALSO NOT INTENDED FOR ANY INFORMATION REGARDING
        WHICH DRUGS OR MEDICAL TREATMENT MAY BE APPROPRIATE FOR YOU, AND YOU
        SHOULD DISREGARD ANY SUCH ADVICE IF DELIVERED THROUGH THE PLATFORM. DO
        NOT DISREGARD, AVOID, OR DELAY IN OBTAINING IN-PERSON CARE FROM YOUR
        DOCTOR OR OTHER QUALIFIED PROFESSIONAL BECAUSE OF INFORMATION OR ADVICE
        YOU RECEIVED THROUGH THE PLATFORM.
        <br />
        <br />
        <b>3. Privacy and Security</b>
        Protecting and safeguarding any information you provide through the
        Platform is extremely important to us. Information about our security
        and privacy practices can be found on our Privacy Policy available at
        (LINK) (The 'Privacy Policy'). BY AGREEING TO THIS AGREEMENT AND/OR BY
        USING THE PLATFORM, YOU ARE ALSO AGREEING TO THE TERMS OF THE PRIVACY
        POLICY. THE PRIVACY POLICY IS INCORPORATED INTO AND DEEMED A PART OF
        THIS AGREEMENT. THE SAME RULES THAT APPLY REGARDING CHANGES AND
        REVISIONS OF THIS AGREEMENT ALSO APPLY TO CHANGES AND REVISIONS OF THE
        PRIVACY POLICY.
        <br />
        <br />
        <b>4. Third Party Content</b>
        The Platform may contain other content, products or services which are
        offered or provided by third parties ("Third Party Content"), links to
        Third Party Content (including but not limited to links to other
        websites) or advertisements which are related to Third Party Content. We
        have no responsibility for the creation of any such Third Party Content,
        including (but not limited to) any related products, practices, terms or
        policies, and we will not be liable for any damage or loss caused by any
        Third Party Content.
        <br />
        <br />
        <b>5. Compliance with Platform Standards</b>
        The platform shall strictly adhere to the guidelines, policies, and
        procedures set forth by PeaceTwogether in the provision of legal
        guidance and counseling services. Failure to comply with these
        guidelines may result in immediate termination of service and other
        legal consequences as deemed appropriate under the law.
        <br />
        <br />
        <b>6. Limitation of Liability</b>
        PeaceTwogether expressly disclaims any responsibility or liability for
        the filing, prosecution, or resolution of any cases, disputes, or legal
        matters between parties who utilize the platform's services. Users are
        solely responsible for their own legal affairs and should seek
        independent legal advice for any actions taken or not taken as a result
        of using this platform.
        <br />
        <br />
        <b>7. Disclaimer of Warranty and Limitation of Liability</b>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE US AND AGREE
        TO HOLD US HARMLESS FROM ANY AND ALL CAUSES OF ACTION AND CLAIMS OF ANY
        NATURE RESULTING FROM THE THERAPIST SERVICES OR THE PLATFORM, INCLUDING
        (WITHOUT LIMITATION) ANY ACT, OMISSION, OPINION, RESPONSE, ADVICE,
        SUGGESTION, INFORMATION AND/OR SERVICE OF ANY THERAPIST AND/OR ANY OTHER
        CONTENT OR INFORMATION ACCESSIBLE THROUGH THE PLATFORM. YOU UNDERSTAND,
        AGREE AND ACKNOWLEDGE THAT THE PLATFORM IS PROVIDED "AS IS" WITHOUT ANY
        EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO
        MERCHANTABILITY, NON-INFRINGEMENT, SECURITY, FITNESS FOR A PARTICULAR
        PURPOSE OR ACCURACY. THE USE OF THE PLATFORM IS AT YOUR OWN RISK. TO THE
        FULLEST EXTENT OF THE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY
        KIND, WHETHER EXPRESSED OR IMPLIED. YOU UNDERSTAND, AGREE AND
        ACKNOWLEDGE THAT WE SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR
        ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY
        DAMAGES. YOU UNDERSTAND, AGREE AND ACKNOWLEDGE THAT OUR AGGREGATE
        LIABILITY FOR DAMAGES ARISING WITH RESPECT TO THIS AGREEMENT AND ANY AND
        ALL USE OF THE PLATFORM WILL NOT EXCEED THE TOTAL AMOUNT OF MONEY PAID
        BY YOU OR ON YOUR BEHALF THROUGH THE PLATFORM IN THE 12 MONTHS PERIOD
        PRIOR TO THE DATE OF THE CLAIM. If the applicable law does not allow the
        limitation of liability as set forth above, the limitation will be
        deemed modified solely to the extent necessary to comply with applicable
        law. This section (limitation of liability) shall survive the
        termination or expiration of this Agreement.
        <br />
        <br />
        <b>8. Your Subscription</b>
        We offer different subscription options that you can choose; including
        billing that can occur weekly, every (4) four weeks, or quarterly. Any
        type of subscription you choose will continue and automatically renew
        until you cancel the membership. By choosing a recurring Membership
        Service, you acknowledge that such paid services have a recurring
        payment and you accept responsibility for all recurring charges prior to
        cancellation. You can cancel the subscription to the service at any time
        for any reason. Your membership must be canceled before it renews in
        order to avoid the next billing cycle. We reserve the right to change
        our subscription or adjust prices of our services. Any changes to your
        membership services will only take effect following proper notice to
        you. Free Trials: Occasionally, we offer free trials for our Services.
        Once a free trial expires, the paid membership will commence following a
        notice to you.
        <br />
        <br />
        <b>9. Your account, representations, conduct and commitments</b>
        You hereby confirm that you are legally able to consent to receive
        Services, or have the consent of a parent or guardian, and are legally
        able to enter into a contract. Minor Consent: Where consent from a
        parent or guardian is required to receive Services, you hereby confirm
        that as the consenting parent or guardian, you have the sole right to
        consent to Services for the minor seeking therapy and are not legally
        mandated to confer with or get consent from any other legal guardian
        before consenting. You also give affirmative consent to the provisions
        set forth in the accompanying Privacy Policy regarding the collection,
        process, and use of personal information on behalf of the minor. You
        also agree that consent to Services remains valid until membership
        cancelled. You hereby confirm and agree that all the information that
        you provided in or through the Platform, and the information that you
        will provide in or through the Platform in the future, is accurate,
        true, current and complete. Furthermore, you agree that during the term
        of this Agreement you will make sure to maintain and update this
        information so it will continue to be accurate, current and complete.
        You agree, confirm and acknowledge that you are responsible for
        maintaining the confidentiality of your password and any other security
        information related to your account (collectively "Account Access"). We
        advise you to change your password frequently and to take extra care in
        safeguarding your password. You agree to notify us immediately of any
        unauthorized use of your Account Access or any other concern for breach
        of your account security. You agree, confirm and acknowledge that we
        will not be liable for any loss or damage that incurred as a result of
        someone else using your account, either with or without your consent
        and/or knowledge. You agree, confirm and acknowledge that you are solely
        and fully liable and responsible for all activities performed using your
        Account Access. You further acknowledge and agree that we will hold you
        liable and responsible for any damage or loss incurred as a result of
        the use of your Account Access by any person whether authorized by you
        or not, and you agree to indemnify us for any such damage or loss. You
        agree and commit not to use the account or Account Access of any other
        person for any reason. You agree and confirm that your use of the
        Platform, including the Therapist Services, are for your own personal
        use only and that you are not using the Platform or the Therapist
        Services for or behalf of any other person or organization. You agree
        and commit not to interfere with or disrupt, or attempt to interfere
        with or disrupt, any of our systems, services, servers, networks or
        infrastructure, or any of the Platform's systems, services, servers,
        networks or infrastructure, including without limitation obtaining
        unauthorized access to the aforementioned. You agree and commit not to
        make any use of the Platform for the posting, sending or delivering of
        either of the following: (a) unsolicited email and/or advertisement or
        promotion of goods and services; (b) malicious software or code; (c)
        unlawful, harassing, privacy invading, abusive, threatening, vulgar,
        obscene, racist or potentially harmful content; (d) any content that
        infringes a third party right including intellectual property rights;
        (e) any content that may cause damage to a third party; (f) any content
        which may constitute, cause or encourage a criminal action or violate
        any applicable law. You agree and commit not to violate any applicable
        local, state, national or international law, statute, ordinance, rule,
        regulation or ethical code in relation to your use of the Platform and
        your relationship with the Therapists and us. If you receive any file
        from us or from a Therapist, whether through the Platform or not, you
        agree to check and scan this file for any virus or malicious software
        prior to opening or using this file. You will indemnify us, defend us,
        and hold us harmless from and against any and all claims, losses, causes
        of action, demands, liabilities, costs or expenses (including, but not
        limited to, litigation and reasonable attorneys' fees and expenses)
        arising out of or relating to any of the following: (a) your access to
        or use of the Platform; (b) any actions made with your account or
        Account Access whether by you or by someone else; (c) your violation of
        any of the provisions of this Agreement; (d) non-payment for any of the
        services (including Therapist Services) which were provided through the
        Platform; (e) your violation of any third party right, including,
        without limitation, any intellectual property right, publicity,
        confidentiality, property or privacy right. This clause shall survive
        expiration or termination of this Agreement. You confirm and agree to
        use only credit cards or other payment means (collectively "Payment
        Means") which you are duly and fully authorized to use, and that all
        payment related information that you provided and will provide in the
        future, to or through the Platform, is accurate, current and correct and
        will continue to be accurate, current and correct. You agree to pay all
        fees and charges associated with your Account on a timely basis and
        according to the fees schedule, the terms and the rates as published in
        the Platform. By providing us with your Payment Means you authorize us
        to bill and charge you through that Payment Means and you agree to
        maintain valid Payment Means information in your Account information.
        <br />
        <br />
        <b>
          10. Modifications, Termination, Interruption and Disruptions to the
          Platform
        </b>
        You understand, agree and acknowledge that we may modify, suspend,
        disrupt or discontinue the Platform, any part of the Platform or the use
        of the Platform, whether to all clients or to you specifically, at any
        time with or without notice to you. You agree and acknowledge that we
        will not be liable for any of the aforementioned actions or for any
        losses or damages that are caused by any of the aforementioned actions.
        The Platform depends on various factors such as software, hardware and
        tools, either our own or those owned and/or operated by our contractors
        and suppliers. While we make commercially reasonable efforts to ensure
        the Platform's reliability and accessibility, you understand and agree
        that no platform can be 100% reliable and accessible and so we cannot
        guarantee that access to the Platform will be uninterrupted or that it
        will be accessible, consistent, timely or error-free at all times.
        <br />
        <br />
        <b>11. Notices</b>
        We may provide notices or other communications to you regarding this
        agreement or any aspect of the Platform, by email to the email address
        that we have on record, by regular mail or by posting it online. The
        date of receipt shall be deemed the date on which such notice is given.
        Notices sent to us must be delivered by email to
        support@peacetwogether.com
        <br />
        <br />
        <b>12. Important notes about our Agreement</b>
        This Agreement and our relationship with you shall both be interpreted
        solely in accordance with the laws of the India excluding any rules
        governing choice of laws. You irrevocably agree that the exclusive venue
        for any action or proceeding arising out of relating to this Agreement
        or our relationship with you, regardless of theory, shall be a subject
        to Kolkata jurisdiction of India. You irrevocably consent to the
        personal jurisdiction of the aforementioned courts and hereby waive any
        objection to the exercise of jurisdiction by the aforementioned courts.
        Nothing in this Agreement, including the choice of the laws of India,
        affects your statutory rights as a consumer to rely on the mandatory
        consumer protection provisions contained in the law of the country in
        which you live. THIS AGREEMENT CONSTITUTES THE ENTIRE AGREEMENT BETWEEN
        YOU AND US. YOU CONFIRM THAT YOU HAVE NOT RELIED UPON ANY PROMISES OR
        REPRESENTATIONS BY US EXCEPT AS SET FORTH IN THIS AGREEMENT. We may
        change this Agreement by posting modifications on the Platform. Unless
        otherwise specified by us, all modifications shall be effective upon
        posting. Therefore, you are encouraged to check the terms of this
        Agreement frequently. The last update date of this Agreement is posted
        at the bottom of the Agreement. By using the Platform after the changes
        become effective, you agree to be bound by such changes to the
        Agreement. If you do not agree to the changes, you must terminate access
        to the Platform and participation in its services. We may freely
        transfer or assign this Agreement or any of its obligations hereunder.
        The paragraph headings in this Agreement are solely for the sake of
        convenience and will not be applied in the interpretation of this
        Agreement. If any provision of this Agreement is held by a court of
        competent jurisdiction to be illegal, invalid, unenforceable, or
        otherwise contrary to law, the remaining provisions of this Agreement
        will remain in full force and effect. To clear any doubt, all clauses
        regarding limitations of liabilities and indemnification shall survive
        the termination or expiration of this Agreement.
      </Stack>
      <Stack id="privacy" paddingTop={12}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <p>Last Updated: 24th May 2024</p>
        Virtue Mind Pvt. Ltd, a company incorporated under the laws of Kolkata,
        India ("Company," "we," "us," or "our"), is committed to protecting the
        privacy and security of personal information collected through our
        counselling booking web platform (the "Application"). This Privacy
        Policy ("Policy") sets forth our practices regarding the collection,
        use, disclosure, and protection of personal information in connection
        with the Application. By accessing or using the Application, you
        acknowledge that you have read, understood, and agree to be bound by the
        terms and conditions of this Policy.
        <br />
        1. Information Collection and Use <br />
        1.1 Personal Information: We may collect personal information that you
        voluntarily provide to us when using the Application, including but not
        limited to your name, contact details, email address, and payment
        information ("Personal Information"). We use this Personal Information
        to provide, maintain, and improve the Application, personalize your
        experience, process and respond to customer service requests, detect and
        prevent illegal activities, enable your access and use of the
        Application, and monitor and analyze usage and trends.
        <br />
        2. Disclosure of Information <br />
        2.1 Affiliated Companies: We may disclose your Personal Information,
        Usage Information, Customer Service Information, and Recordings
        (collectively, "Collected Information") to our affiliated companies to
        provide services on our behalf. <br />
        2.2 Service Providers: We may disclose your Collected Information to our
        third-party service providers, such as payment processors, to facilitate
        the provision of the Application and related services. <br />
        2.3 Legal Requirements: We may disclose your Collected Information if
        required to do so by law or in the good faith belief that such action is
        necessary to comply with legal obligations, protect and defend our
        rights or property, prevent or investigate possible wrongdoing in
        connection with the Application, or protect the personal safety of users
        of the Application or the public. <br />
        2.4 Consent: We may disclose your Collected Information to other third
        parties with your express consent.
        <br />
        3. Data Security <br />
        3.1 We implement commercially reasonable security measures to protect
        the Collected Information from unauthorized access, disclosure,
        alteration, or destruction. However, no method of transmission over the
        Internet or electronic storage is 100% secure, and we cannot guarantee
        the absolute security of your Collected Information.
        <br />
        4. International Data Transfers <br />
        4.1 Your Collected Information may be transferred to, stored, and
        processed in countries other than the one in which it was collected,
        including but not limited to the United States and Europe. These
        countries may have data protection laws that are different from the laws
        of your country. By using the Application, you consent to the transfer
        of your Collected Information to these countries.
        <br />
        5. Your Rights <br />
        5.1 You may have certain rights under applicable data protection laws,
        including the right to access, update, or delete your Personal
        Information. To exercise these rights, please contact us using the
        information provided in the "Contact Us" section below.
        <br />
        6. Children's Privacy <br />
        6.1 The Application is not intended for use by children under the age of
        13. We do not knowingly collect personal information from children under
        13. If we become aware that a child under 13 has provided us with
        personal information, we will take steps to delete such information.
        <br />
        7. Changes to this Policy <br />
        7.1 We reserve the right to modify this Policy at any time. If we make
        material changes to this Policy, we will notify you by posting the
        revised Policy on our website or by other means. Your continued use of
        the Application after any such changes will be deemed your acceptance of
        the revised Policy.
        <br />
        8. Contact Us <br />
        8.1 If you have any questions, concerns, or requests regarding this
        Policy or our privacy practices, please contact us at: Virtue Mind Pvt.
        Ltd ,<br />
        23/A/V/S D.H. Road, Flat-A, 4th Floor, Kolkata, West Bengal, India,
        700053 <br />
        support@peacetwogether.com
        <br />
        +91 6291075366
        <br />
        BY USING THE APPLICATION, YOU SIGNIFY YOUR ACCEPTANCE OF THIS POLICY. IF
        YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT USE THE APPLICATION. YOUR
        CONTINUED USE OF THE APPLICATION FOLLOWING THE POSTING OF CHANGES TO
        THIS POLICY WILL BE DEEMED YOUR ACCEPTANCE OF THOSE CHANGES.
      </Stack>
      <Stack id="refund" paddingTop={12}>
        <Typography variant="h4" gutterBottom>
          Refund Policy
        </Typography>
        We understand that sometimes a customer may need to cancel or change
        their counselling reservation. Therefore, we have a refund policy in
        place to ensure that customers are not charged any unexpected fees. For
        bookings made with PeaceTwogether, customers can cancel their
        reservation up to 24 hours before their meeting and receive a 100%
        refund, minus any applicable taxes and fees*. No cancellation within
        24hrs of the scheduled meeting. If a customer does not show up for their
        meeting, the customer will be charged for the entire meeting and no
        refund will be issued. The refund will be processed in 7-14 working days
        subject to verification. If you need to make any changes or cancel your
        meeting, please contact us at support@peacetwogether.com.
      </Stack>
      <LandingFooter />
    </Stack>
  );
}
