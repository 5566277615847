import { IconButton, Typography } from "@mui/material";
import { Bars } from "react-loader-spinner";
import Stack from "@mui/material/Stack";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
export default function BottomChatBar({
  disclaimer,
  handleSubmit,
  newQuestion,
  setNewQuestion,
  loader,
  hasAudioAccess = false,
  setIsRecording = () => {},
}) {
  const enterHandle = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <Stack
      sx={{
        position: "fixed",
        left: { xs: "50%", md: `calc(50% + ${240 / 2}px)` }, // Adjust for marginLeft on md screens
        bottom: 0,
        transform: "translateX(-50%)",
        width: {xs:'100%',md:"80%",lg:'85%'},
        alignItems: "center",
        backgroundColor: "white",
        pt: 0.5,
      }}
    >
      <TextField
        multiline
        value={newQuestion}
        onChange={(e) => setNewQuestion(e.target.value)}
        autoFocus
        autoComplete="off"
        placeholder="Enter your message here"
        disabled={loader}
        onKeyDown={!loader ? enterHandle : null}
        InputProps={{
          style: { borderRadius: "100px" }, // Adjust the border radius here
          endAdornment: (
            <InputAdornment position="end">
              {hasAudioAccess && (
                <IconButton
                  sx={{
                    zIndex: 10,
                    backgroundColor: "white",
                    color: "purple",
                    p: 1,
                    mr: 1,
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => setIsRecording(true)}
                  edge="end"
                >
                  <MicIcon />
                </IconButton>
              )}
              <IconButton
                sx={{
                  zIndex: 10,
                  backgroundColor: "white",
                  color: "purple",
                  p: 1,
                }}
                aria-label="toggle password visibility"
                onClick={() => handleSubmit()}
                edge="end"
              >
                {loader ? (
                  <Bars
                    height="36"
                    width="36"
                    color="#4fa94d"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          zIndex: 20,
          paddingX: 0.5,
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "50%", // Adjust this value for different border radius
          },
          border: "none",
          width: "90%",
          maxWidth: "1200px", // Adjust as needed
          backgroundColor: "#f0f4f9",
          "& .MuiInputBase-input": {
            backgroundColor: "#f0f4f9", // Change to your desired background color
          },
          "& .MuiOutlinedInput-input": {
            backgroundColor: "#f0f4f9", // Ensure consistency in background color
          },
          "&:hover .MuiInputBase-input": {
            backgroundColor: "#f0f4f9", // Optional: Change on hover
          },
          "&.Mui-focused .MuiInputBase-input": {
            backgroundColor: "#f0f4f9", // Optional: Change when focused
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#f0f4f9", // Customizing border color
            },
            "&:hover fieldset": {
              borderColor: "#f0f4f9", // Customizing border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#f0f4f9",
            },
            backgroundColor: "#f0f4f9", // Customizing background color
            border: "none",
          },
        }}
      />
      <Typography
        color={"gray"}
        sx={{
          width: "100%",
          textAlign: "center",
          fontSize: 12,
          zIndex: 10,
          padding: 1,
          backgroundColor: { xs: "white", md: "white" },
        }}
      >
        {disclaimer}
      </Typography>
    </Stack>
  );
}
